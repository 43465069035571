import React from "react";
import playgroundService from "../../domain/playground/PlaygroundService";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../styles/colors";
import {Grid, Paper, TextField} from "@material-ui/core";
import defaultRoomImage from '../iconDefaultRoomImage.png'
import AppButton from "../view/component/AppButton";
import {Storage} from "aws-amplify";

const LectureView = ({meetId, password, onInputZoomData, playground, zoomData}) => {
  const classes = useStyles();

  const [inputMeetId, setInputMeetId] = React.useState("");
  const [inputPassword, setInputPassword] = React.useState("");
  React.useEffect(() => {
    setInputMeetId(meetId);
    setInputPassword(password);
  }, [meetId, password])


  const confirmZoom = () => {
    try {
      const {meetId, password} = checkValidation(inputMeetId, inputPassword);
      console.log(meetId, password);
      onInputZoomData && onInputZoomData(meetId, password)
    } catch (e) {
      if (e.message === "INVALID_MEET_ID") {
        alert("회의 ID가 잘못되었습니다.")
      } else if (e.message === "INVALID_PASSWORD") {
        alert("회의 암호가 잘못되었습니다.")
      }
      alert('알 수 없는 에러가 발생하였습니다.');
    }
  }

  const checkValidation = (inputMeetId, inputPassword) => {
    let meetId = inputMeetId;
    let password = inputPassword;

    meetId = meetId.replaceAll(" ", "");
    const reg = /^\d+$/;
    if (!reg.test(meetId)) {
      throw new Error("INVALID_MEET_ID")
    }
    if (meetId.length !== 11 && meetId.length !== 10) {
      throw new Error("INVALID_MEET_ID")
    }
    if (reg.test(password)) {
      throw new Error("INVALID_PASSWORD")
    }
    // if (password.length !== 6) {
    //   throw new Error("INVALID_PASSWORD")
    // }
    return ({
      meetId, password
    })
  }

  const [imageUrl, setImageUrl] = React.useState(null);
  React.useEffect(() => {
    const getImage = async () => {
      if (playground.picture) {
        if (playground.picture == 'null' || !playground.picture) {
          setImageUrl(null);
        } else {
          Storage.get(playground.picture, {level: 'public'}).then(url => {
            setImageUrl(url);
          });
        }
      }
    };
    getImage();
  }, [playground.picture]);

  return (
    <>
      {
        zoomData ?
          <div className={classes.lecture_Page}>
            <Grid container spacing={2} lg='auto' justifyContent="flex-start" direction="row" alignItems="flex-start">
              {playground.rounds[playground.rounds.length - 1].userSkips.map(userSkip => {
                return (
                  <Grid item>
                    <Paper style={{
                      display: 'flex',
                      fontSize: 'calc(12px + 1vmin)',
                      width: '140px',
                      height: '130px',
                      padding: '8px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>{userSkip.name}<br/>{userSkip.count}</Paper>
                  </Grid>
                );
              })}
            </Grid>
          </div> :
          <div style={{
            backgroundImage: imageUrl ? `url(${imageUrl})` : `url(${defaultRoomImage})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}>
            <div className={classes.lecture_Page}  style={{backgroundColor: 'rgba(255,255,255,0.6)', justifyContent: 'center',}}>
              <div className={classes.lecture_Title}>ZOOM 수업 시작하기</div>
              <br/>
              <div className={classes.lecture_Input_Container}>
                <span style={{width: '100px'}}>회의 ID</span>
                <TextField
                  required
                  id="zoomId"
                  variant="outlined"
                  value={inputMeetId}
                  label="ZOOM 회의 ID"
                  size="small"
                  onChange={(e) => {
                    setInputMeetId(e.target.value)
                  }}
                  className={classes.lecture_Input_Text}
                />
              </div>
              <div className={classes.lecture_Input_Container}>
                <span style={{width: '100px'}}>회의 암호</span>
                <TextField
                  required
                  id="zoomPassword"
                  variant="outlined"
                  value={inputPassword}
                  label="ZOOM 회의 암호"
                  size="small"
                  onChange={(e) => {
                    setInputPassword(e.target.value)
                  }}
                  onKeyDown={e => {
                    if (e.code === 'Enter') {
                      confirmZoom();
                    }
                  }}
                  className={classes.lecture_Input_Text}
                />
              </div>
              <br/>
              <AppButton
                to={"#"}
                onClick={confirmZoom}
                className={classes.lecture_Confirm}>
                수업 시작
              </AppButton>
            </div>
          </div>
      }
    </>
  )
}

const useStyles = makeStyles({
  lecture_Page: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    minHeight: '80vh',
    padding: '10px',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },

  lecture_Image: {
    // width: '95%',
    maxHeight: '25vh',
  },
  lecture_Title: {
    fontSize: 'calc(10px + 2vmin)',
    fontWeight: 'bold',
  },

  lecture_Input_Container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lecture_Input_Text: {
    borderRadius: 5,
    backgroundColor: colors.WHITE,
    margin: '10px',
  },
  lecture_Confirm: {
    fontSize: 'calc(8px + 1vmin)',
    backgroundColor: colors.PINK,
    '&:hover': {
      backgroundColor: colors.PINK+'AA',
      cursor: 'pointer',
    },
    paddingLeft: '3vw',
    paddingRight: '3vw',
    color: colors.WHITE,
    fontWeight: 'bold',
  }
});

export default LectureView;
