import React from 'react';
import {Storage} from 'aws-amplify';
import defaultClassImage from '../../iconDefaultClassImage.png'
import defaultRoomImage from '../../iconDefaultRoomImage.png'

export const StorageImage = ({imageKey, type, className, style}) => {
    const [imgUrl, setImageUrl] = React.useState(null);

    React.useEffect(() => {
        const getImage = async () => {
            if (imageKey) {
                if (imageKey == 'null' || !imageKey) {
                    setImageUrl(null);
                } else {
                    Storage.get(imageKey, {level: 'public'}).then(url => {
                        console.log(url);
                        setImageUrl(url);
                    });
                }
            }
        };
        getImage();
    }, [imageKey]);

    return (
        <img
            src={imgUrl ?
                    imgUrl :
                type === 'USER' ?
                    defaultClassImage :
                type === 'PLAYGROUND' ?
                    defaultClassImage:
                type === 'ROOM' ?
                    defaultRoomImage :
                    ''
            }
            className={className}
            style={style}
        />
    );
};
