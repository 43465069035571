export default {
  SignUpErrorCode: {
    InvalidParameter: 'InvalidParameter', //입력값 오류 (모든 값이 입력되지 않았거나 입력값이 잘못됨)
    EmailExistsException: 'EmailExistsException', //이미 존재하는 이메일
    PasswordTooShortException: 'PasswordTooShortException', //비밀번호는 6자리 이상이어야함
  },
  SignInErrorCode: {
    EmailNotConfirmedException: 'EmailNotConfirmedException', //이메일이 확인되지 않음 (확인코드 입력)
    UserNotFoundException : 'UserNotFoundException', // 존재하지 않는 이메일
    NotAuthorizedException : 'NotAuthorizedException' // 이메일이나 비밀번호 틀림
  },
  ConfirmEmailErrorCode: {
    CodeMismatchException: 'CodeMismatchException', //이메일 확인코드가 틀림
    AlreadyConfirmedException: 'AlreadyConfirmedException' //이메일이 이미 확인됨. 그냥 다시 로그인하면 됨
  }
};
