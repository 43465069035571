import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {uuid} from 'uuidv4';
import {Storage} from 'aws-amplify';
import {colors} from '../styles/colors';
import AppButton from '../view/component/AppButton'
import {StorageImage} from '../view/component/StorageImage'
import playgroundService from '../../domain/playground/PlaygroundService'
import {Modal, Snackbar, TextField} from "@material-ui/core";
import defaultClassImage from '../iconDefaultClassImage.png';
import {Alert, AlertTitle} from "@material-ui/lab";

export const CreateModifyPlaygroundModal = ({isOpen, onClose, type, playground}) => {
    const classes = useStyles();

    const refPlaygroundData = React.useRef({
        playgroundId: '',
        playgroundName: '',
        detail: '',
        picture: null,
        isEdit: false,
    });
    const [playgroundData, setPlaygroundData] = React.useState({
        playgroundId: '',
        playgroundName: '',
        detail: '',
        picture: null,
        isEdit: false,
    });

    const [isAlert, setAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState(0);

    const [image, setImage] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [beforeImage, setBeforeImage] = React.useState(null);

    React.useEffect(() => {
        return (refPlaygroundData.current = null);
    }, []);

    React.useEffect(() => {
        if (type === 'MODIFY') {
            _setPlaygroundData(playground);
        }
    }, [playground, isOpen]);

    React.useEffect(() => {
        if (playgroundData) {
            if (playgroundData.isEdit) {
                editPlayground();
            }
        }
    }, [playgroundData]);

    const _setPlaygroundData = async value => {
        if (value) {
            refPlaygroundData.current = {
                ...playgroundData,
                ...value,
            };
            setPlaygroundData({
                ...playgroundData,
                ...value,
            });
        }
    };

    /** 방 사진 업로드*/
    const uploadImage = async (file) => {
        try {
            const uploadResponse = await Storage.put(uuid(), file, {
                level: 'public',
                contentType: file.type,
            });

            return uploadResponse;
        } catch (e) {
            _setAlert(true, 1)
            throw new Error(e);
        }
        return;
    };

    /** playground 수정*/
    const editPlayground = async () => {
        if (type === 'MODIFY' && playground) {
            try {
                await playgroundService.updatePlayground(playground.playgroundId, {
                    playgroundName: playgroundData.playgroundName,
                    detail: playgroundData.detail,
                    picture: playgroundData.picture,
                });
                setImage(null);
            } catch (e) {
                _setAlert(true, 2);
            }
        } else {
            // playground가 연결되지 않음
            _setAlert(true, 3);
        }
    };

    const _onClose = () => {
        setImage(null);
        refPlaygroundData.current = {
            playgroundId: '',
            playgroundName: '',
            detail: '',
            picture: null,
            isEdit: false,
        };
        setPlaygroundData({
            playgroundId: '',
            playgroundName: '',
            detail: '',
            picture: null,
            isEdit: false,
        });
        onClose();
    }

    const _setAlert = (bool, type) => {
        if (isAlert) {
            setAlert(bool);
            setAlertType(type);
        } else {
            setAlertType(type);
            setAlert(bool);
        }
    };

    return (
        <>
            <Modal
                open={isOpen}
                onClose={_onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.container}>
                    <div>
                        {type === 'CREATE' ? (
                            <>
                                <div className={classes.titleText}>방 만들기</div>
                                <hr/>
                                <div className={classes.mainText}>
                                    {'수업을 위한 방을 생성해 보세요.'}
                                </div>
                            </>
                        ) : (
                            <div className={classes.titleText}>방 정보 수정</div>
                        )}
                        <TextField
                            required
                            variant="outlined"
                            size="small"
                            InputProps={{
                                classes: {
                                    input: classes.classNameText
                                }
                            }}
                            className={classes.classNameContainer}
                            placeholder={'방 제목을 입력해주세요'}
                            value={playgroundData ? playgroundData.playgroundName : ''}
                            onChange={async e => {
                                setBeforeImage(playgroundData.picture);
                                await _setPlaygroundData({playgroundName: e.target.value});
                            }}
                        />
                        <TextField
                            variant="outlined"
                            size="small"
                            InputProps={{
                                classes: {
                                    input: classes.classDefinitionText
                                }
                            }}
                            className={classes.classDefinitionContainer}
                            placeholder={'방에 대한 설명을 입력해주세요'}
                            value={playgroundData ? playgroundData.detail : ''}
                            onChange={async e => {
                                await _setPlaygroundData({detail: e.target.value});
                            }}
                            multiline={true}
                            rowsMax={2}
                        />

                        <div className={classes.imageContainer}>
                            {playground ? (
                                image ? (
                                    image === 'DEFAULT' ? (
                                        <StorageImage
                                            className={classes.image}
                                            imageKey={playgroundData.picture}
                                            type={'PLAYGROUND'}
                                            style={{
                                                width: '10vw',
                                                height: '10vw',
                                                minWidth: 70,
                                                minHeight: 70,
                                                borderRadius: 300,
                                                borderStyle: 'solid',
                                                borderColor: colors.CHARCOAL,
                                                borderWidth: 1,
                                                resize: 'contain',
                                            }}
                                        />
                                    ) : (
                                        <img
                                            className={classes.image}
                                            src={image}
                                            style={{
                                                width: '10vw',
                                                height: '10vw',
                                                minWidth: 70,
                                                minHeight: 70,
                                                borderRadius: 300,
                                                borderStyle: 'solid',
                                                borderColor: colors.CHARCOAL,
                                                borderWidth: 1,
                                                resize: 'contain',
                                            }}
                                        />
                                    )
                                ) : (
                                    <StorageImage
                                        class={classes.image}
                                        imageKey={playgroundData.picture}
                                        type={'PLAYGROUND'}
                                        style={{
                                            width: '10vw',
                                            height: '10vw',
                                            minWidth: 70,
                                            minHeight: 70,
                                            borderRadius: 300,
                                            borderStyle: 'solid',
                                            borderColor: colors.CHARCOAL,
                                            borderWidth: 1,
                                            resize: 'contain',
                                        }}
                                    />
                                )
                            ) : (
                                <img
                                    className={classes.image}
                                    src={
                                        image
                                            ? image
                                            : playgroundData.picture &&
                                            playgroundData.picture !== 'null'
                                            ? playgroundData.picture
                                            : defaultClassImage
                                    }
                                    style={{
                                        width: '10vw',
                                        height: '10vw',
                                        minWidth: 70,
                                        minHeight: 70,
                                        borderRadius: 300,
                                        borderStyle: 'solid',
                                        borderColor: colors.CHARCOAL,
                                        borderWidth: 1,
                                        resize: 'contain',
                                    }}
                                />
                            )}
                            <div
                                style={{flexDirection: 'column', marginLeft: '2vw'}}>
                                <div style={{fontSize: 'calc(3px + 1.5vmin)', marginBottom: '1vh'}}>
                                    {'이미지를 등록하여'}<br/>{'더욱 특별한 방을 만들어 보세요'}
                                </div>
                                <AppButton onClick={() => {document.getElementById('get-image').click()}} className={classes.getImageButton}>
                                    이미지 선택
                                </AppButton>
                                <input
                                    accept= "image/*"
                                    style={{display: 'none'}}
                                    id="get-image"
                                    multiple={false}
                                    type="file"
                                    onChange={async (e) => {
                                        const file = e.target.files[0];
                                        setFile(e.target.files.item(0));

                                        if (file) {
                                            const fr = new FileReader();

                                            fr.onload = async (event) => {
                                                setBeforeImage(playgroundData.picture);
                                                setImage(event.target.result);
                                            };

                                            fr.readAsDataURL(file);
                                        }
                                        else {
                                            setImage(null);
                                            await _setPlaygroundData({
                                                picture: null
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'space-around'}}>
                            <AppButton
                                onClick={async () => {
                                    if (playgroundData.playgroundName === '') {
                                        _setAlert(true, 4);
                                        return;
                                    }

                                    if (type === 'MODIFY') {
                                        if (image) {
                                            if (image === 'DEFAULT') {
                                                await _setPlaygroundData({
                                                    picture: 'null',
                                                    isEdit: true,
                                                });
                                            } else {
                                                try {
                                                    const response = await uploadImage(file);
                                                    await Storage.remove(beforeImage);
                                                    await _setPlaygroundData({
                                                        picture: response.key,
                                                        isEdit: true,
                                                    });
                                                } catch (e) {
                                                    _setAlert(true, 5);
                                                }
                                            }
                                        } else {
                                            await _setPlaygroundData({
                                                isEdit: true,
                                            });
                                        }
                                    } else {
                                        if (image) {
                                            if (image === 'DEFAULT') {
                                                try {
                                                    await playgroundService.createPlayground(
                                                        playgroundData.playgroundName,
                                                        playgroundData.detail,
                                                        'null',
                                                    );
                                                } catch (e) {
                                                    _setAlert(true, 6);
                                                }
                                            } else {
                                                try {
                                                    const response = await uploadImage(file);
                                                    await playgroundService.createPlayground(
                                                        playgroundData.playgroundName,
                                                        playgroundData.detail,
                                                        response.key,
                                                    );
                                                } catch (e) {
                                                    _setAlert(true, 5);
                                                }
                                            }
                                        } else {
                                            try {
                                                await playgroundService.createPlayground(
                                                    playgroundData.playgroundName,
                                                    playgroundData.detail,
                                                    'null',
                                                );
                                            } catch (e) {
                                                _setAlert(true, 6);
                                            }
                                        }
                                    }
                                    _onClose();
                                }}
                                className={classes.appButton}
                            >{type === 'MODIFY' ? '수정하기' : '방 만들기'}</AppButton>
                            <AppButton
                                onClick={_onClose}
                                className={classes.appButton}
                            >취소</AppButton>
                        </div>
                    </div>
                </div>
            </Modal>

            <Snackbar
                open={isAlert}
                autoHideDuration={2000}
                onClose={() => {
                    _setAlert(false, 0)
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {
                    alertType === 1 ?
                        <Alert severity='error'>
                            <AlertTitle>이미지 업로드 실패.</AlertTitle>
                            이미지를 업로드 할 수 없습니다. 인터넷 연결을 확인하거나 잠시 후 다시 시도해주세요.
                        </Alert> :
                    alertType === 2 ?
                        <Alert severity='error'>
                            <AlertTitle>방 정보 수정 실패.</AlertTitle>
                            방 정보를 업데이트 할 수 없습니다. 인터넷 연결을 확인하거나 잠시 후 다시 시도해주세요.
                        </Alert> :
                    alertType === 3 ?
                        <Alert severity='error'>
                            <AlertTitle>방에 연결되지 않았습니다.</AlertTitle>
                            방에 연결할 수 없습니다. 인터넷 연결을 확인하거나 잠시 후 다시 시도해주세요.
                        </Alert> :
                    alertType === 4 ?
                        <Alert severity='error'>
                            <AlertTitle>방 제목이 설정되지 않았습니다.</AlertTitle>
                            방 제목을 입력 후 다시 시도해주세요.
                        </Alert> :
                    alertType === 5 ?
                        <Alert severity='error'>
                            <AlertTitle>이미지 업로드 실패.</AlertTitle>
                            이미지를 업로드 할 수 없습니다. 인터넷 연결을 확인하거나 잠시 후 다시 시도해주세요.
                        </Alert> :
                    alertType === 6 ?
                        <Alert severity='error'>
                            <AlertTitle>방 생성 실패.</AlertTitle>
                            방을 생성할 수 없습니다. 인터넷 연결을 확인하거나 잠시 후 다시 시도해주세요.
                        </Alert> :
                        <></>
                }
            </Snackbar>
        </>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        position: 'absolute',
        left: '25vw',
        top: '10vh',
        backgroundColor: colors.WHITE,
        flexDirection: 'column',
        maxHeight: '90vh',
        width: '50%',
        padding: '3%',
        borderRadius: 15,
    },
    titleText: {
        fontSize: 'calc(7px + 2vmin)',
        textAlign: 'left',
        marginBottom: '1vh',
        fontWeight: 'bold',
    },
    mainText: {
        fontSize: 'calc(9px + 1vmin)',
        textAlign: 'left',
        marginTop: '1vh',
        marginBottom: '3vh',
        fontWeight: 'bold',
    },

    classNameContainer: {
        width: '100%',
        marginBottom: '3vh',
    },
    classNameText: {
        display: 'flex',
        fontSize: 'calc(1px + 2vmin)',
        maxHeight: '2vh',
    },
    classDefinitionContainer: {
        width: '100%',
        marginBottom: '3vh',
    },
    classDefinitionText: {
        display: 'flex',
        fontSize: 'calc(1px + 2vmin)',
    },

    imageContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '3vh',
    },
    image: {
        maxWidth: '30%',
        maxHeight: '30%',
        borderRadius: 50,
        borderStyle: 'solid',
        borderColor: colors.CHARCOAL,
        borderWidth: 1,
    },

    appButton: {
        display: 'flex',
        fontSize: 'calc(3px + 1.5vmin)',
        fontWeight: 'bold',
        color: colors.WHITE,
        width: '6vw',
        backgroundColor: colors.PINK,
        '&:hover': {
            backgroundColor: colors.PINK+'AA',
            cursor: 'pointer',
        },
    },
    getImageButton: {
        color: colors.PINK,
        fontSize: 'calc(1px + 1.5vmin)',
        fontWeight: 'bold',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 5,
        backgroundColor: colors.WHITE,
        '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.05)',
            cursor: 'pointer',
        },
    }
});
