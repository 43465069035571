import Playground from '../../../data/model/Playground';
import FirebaseRepository from '../../../data/repository/firebase/FirebaseRepository';

export default class RunningPlaygroundHandler {
  firebaseRepository;
  observer;
  constructor(firebaseRepository) {
    this.firebaseRepository = firebaseRepository;
  }

  observe(playgroundId, observer: Playground => void) {
    this.clearObserve();

    this.observer = observer;
    this.firebaseRepository.registPlaygroundObserver(
      playgroundId,
      playground => {
        if (this.observer) {
          this.observer(playground);
        }
      },
    );
  }

  clearObserve() {
    //clear this.observer
    if (this.firebaseRepository) {
      this.firebaseRepository.clearPlaygroundObserver();
    }
    this.observer = null;
  }
}
