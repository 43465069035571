import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {colors} from '../styles/colors';
import AppButton from '../view/component/AppButton'
import {Modal, Snackbar} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";

export const CheckIsDeleteModal = ({isOpen, onClose, playgroundId, onAlert}) => {
    const classes = useStyles();

    const [isAlert, setAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState(0);

    const _onClose = async (isDelete) => {
        if (isDelete) {
            try {
                await onClose(playgroundId);
            } catch {
                onAlert(true, 1);
            }
        } else {
            onClose(null);
        }
    }

    const _setAlert = (bool, type) => {
        if (isAlert) {
            setAlert(bool);
            setAlertType(type);
        } else {
            setAlertType(type);
            setAlert(bool);
        }
    };

    return (
        <>
            <Modal
                open={isOpen}
                onClose={() => _onClose(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.container}>
                    <div className={classes.titleText}>{'정말로 방을 '} <span style={{color: colors.CORAL}}>삭제</span>{' 하시겠습니까?'}</div>
                    <br/>
                    <div className={classes.mainText}>{'방을 삭제하면 저장되어있는 모든 데이터가 삭제되고'}<br/><span style={{color: colors.CORAL}}>삭제된 데이터는 복구할 수 없습니다.</span></div>

                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                        <AppButton
                            onClick={async () => {
                                _onClose(true);
                            }}
                            className={classes.appButton}
                        >삭제하기</AppButton>
                        <AppButton
                            onClick={() => _onClose(false)}
                            className={classes.appButton}
                        >취소</AppButton>
                </div>
                </div>
            </Modal>

            <Snackbar
                open={isAlert}
                autoHideDuration={2000}
                onClose={() => {
                    _setAlert(false, 0)
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {
                    alertType === 1 ?
                        <Alert severity='error'>
                            <AlertTitle>방 삭제 실패.</AlertTitle>
                            방을 삭제할 수 없습니다. 인터넷 연결을 확인하거나 잠시 후 다시 시도해주세요.
                        </Alert> :
                        <></>
                }
            </Snackbar>
        </>
    );
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        position: 'absolute',
        left: '30vw',
        top: '10vh',
        backgroundColor: colors.WHITE,
        flexDirection: 'column',
        maxHeight: '90vh',
        width: '40%',
        padding: '3%',
        borderRadius: 15,
    },
    titleText: {
        fontSize: 'calc(7px + 2vmin)',
        textAlign: 'left',
        marginBottom: '1vh',
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    mainText: {
        fontSize: 'calc(9px + 1vmin)',
        textAlign: 'left',
        marginTop: '1vh',
        marginBottom: '3vh',
        fontWeight: 'bold',
        alignSelf: 'center',
    },

    appButton: {
        display: 'flex',
        fontSize: 'calc(3px + 1.5vmin)',
        fontWeight: 'bold',
        color: colors.WHITE,
        width: '7vw',
        backgroundColor: colors.PINK,
        '&:hover': {
            backgroundColor: colors.PINK+'AA',
            cursor: 'pointer',
        },
    },
});
