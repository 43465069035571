import firebase from "firebase/app";
import "firebase/database";

// import database from '@react-native-firebase/database';
import UserDetail from '../../model/UserDetail';
import Playground from '../../model/Playground';
import moment from 'moment-timezone';
import config from '../../../config'

firebase.initializeApp({
  apiKey: "AIzaSyDKuZveKujsvpeFCbRqvAnsJYajrDjZYsc",
  authDomain: "ropejump-144bf.firebaseapp.com",
  databaseURL: config.firebaseUrl,
  storageBucket: "ropejump-144bf.appspot.com"
});

const database = firebase.database;

export default class FirebaseRepository {
  userId;
  playgroundId;

  user;

  userDetailChangeListener;
  playgroundChangeListener;

  userDetailObserver;
  playgroundObserver;

  onDisconnectRefs = [];
  controllerRef;

  constructor(userId) {
    this._registListener(userId);
  }

  destroy() {
    this.clearPlaygroundObserver();
    this.clearUserDetailObserver();
    this._clearListener();
  }

  _registListener(userId) {
    this._clearListener();

    if (userId) {
      this.userId = userId;
    }
    this.userDetailChangeListener = database()
      .ref(`/users/${this.userId}`)
      .on('value', snapshot => {
        let user = snapshot.val();
        this.user = this._convertUser(user);

        if (this.userDetailObserver) {
          this.userDetailObserver(this.user);
        }
      });
  }

  _clearListener() {
    if (this.userDetailChangeListener && this.userId) {
      database()
        .ref(`/users/${this.userId}`)
        .off('value', this.userDetailChangeListener);
    }
  }

  setUserId(userId) {
    if (this.userId != userId) {
      this._clearListener();
      this._registListener(userId);
    }
  }

  registUserDetailObserver(observer: UserDetail => void) {
    this.userDetailObserver = observer;

    //regist 후 바로 콜백
    if (this.user) {
      this.userDetailObserver(this.user);
    }
  }
  clearUserDetailObserver() {
    this.userDetailObserver = null;
  }

  registPlaygroundObserver(playgroundId, observer: Playground => void) {
    this.playgroundId = playgroundId;
    this.playgroundObserver = observer;

    let path = `/playgrounds/${playgroundId}`;
    this.playgroundChangeListener = database()
      .ref(path)
      .on('value', snapshot => {
        let playground: Playground = snapshot.val();
        if (playground) {
          playground = this._convertPlayground(playground);
        }

        if (this.playgroundObserver) {
          this.playgroundObserver(playground);
        }
      });
  }

  clearPlaygroundObserver() {
    this.playgroundObserver = null;

    if (this.playgroundId && this.playgroundChangeListener) {
      database()
        .ref(`/playgrounds/${this.playgroundId}`)
        .off('value', this.playgroundChangeListener);
    }

    this.playgroundId = null;
  }

  async setHostConnect(playgroundId, connect) {
    //disconnect trigger에 물려있음
    const presenceRef = database().ref(
      `/playgrounds/${playgroundId}/hostConnect`,
    );
    await presenceRef.set(connect);
  }

  async setRunningMode(playgroundId, mode) {
    //disconnect trigger에 물려있음
    const db = database().ref(`playgrounds/${playgroundId}`).child('runningMode');
    await db.set(mode);
  }

  async removeRunningMode(playgroundId) {
    const db = database().ref(`playgrounds/${playgroundId}`).child('runningMode');
    await db.set(null);
  }

  async addZoomData(playgroundId, {meetId, password}) {
    //disconnect trigger에 물려있음
    const db = database().ref(`playgrounds/${playgroundId}`).child('zoom');
    await db.set({meetId, password});
  }

  async removeZoomData(playgroundId) {
    const db = database().ref(`playgrounds/${playgroundId}`).child('zoom');
    await db.set(null);
  }

  async setControlId(playgroundId, controllerId) {
    const controllerRef = database()
      .ref(`playgrounds/${playgroundId}`)
      .child('controller')
      .onDisconnect();
    await controllerRef.set(null);
    // this.onDisconnectRefs.push(controllerRef);
    this.controllerRef = controllerRef;

    const db = database().ref(`playgrounds/${playgroundId}`).child('controller');
    await db.set(controllerId);
  }
  async removeControlId(playgroundId) {
    if (this.controllerRef) {
      this.controllerRef.cancel();
      this.controllerRef = null;
    }
    const db = database().ref(`playgrounds/${playgroundId}`).child('controller');
    await db.set(null);
  }

  async setHostDisconnectTrigger(playgroundId) {
    const connectStatusRef = database()
      .ref(`/playgrounds/${playgroundId}/hostConnect`)
      .onDisconnect();
    await connectStatusRef.set(false);

    const zoomDataRef = database()
      .ref(`playgrounds/${playgroundId}`)
      .child('zoom')
      .onDisconnect();
    await zoomDataRef.set(null);

    const runningModeRef = database()
      .ref(`playgrounds/${playgroundId}`)
      .child('runningMode')
      .onDisconnect();
    await runningModeRef.set(null);

    this.onDisconnectRefs.push(connectStatusRef);
    this.onDisconnectRefs.push(zoomDataRef);
    this.onDisconnectRefs.push(runningModeRef);
  }

  async cancelHostDisconnectTrigger(playgroundId) {
    for (let ref of this.onDisconnectRefs) {
      ref.cancel();
    }
    this.onDisconnectRefs = [];
  }

  async updateUserPlayground(
    userId,
    targetPlayground,
    data: {
      playgroundName: string,
      detail: string,
      picture: string,
    },
  ) {
    const {playgroundId, host} = targetPlayground;
    if (!playgroundId || !host) {
      throw new Error('Invalid Parameter');
    }
    const hostUserId = host.userId;
    if (!hostUserId) {
      throw new Error('Invalid Parameter');
    }

    if (data) {
      data = {
        playgroundName: data.playgroundName,
        detail: data.detail,
        picture: data.picture,
      };
    }
    let db;
    if (hostUserId == userId) {
      db = database().ref(
        `users/${userId}/managingPlaygrounds/${playgroundId}`,
      );
    } else {
      db = database().ref(`users/${userId}/enteredPlaygrounds/${playgroundId}`);
    }
    if (data) {
      return db.update(data);
    } else {
      return db.set(null);
    }
  }
  async removeUserPlayground(userId, targetPlayground) {
    await this.updateUserPlayground(userId, targetPlayground, null);
  }

  async updateUserPlaygroundTimesId(userId, playground) {
    if (!playground || !playground.host) {
      throw new Error('playground not found');
    }
    if (playground.host.userId == userId) {
      const db = database().ref(
        `users/${userId}/managingPlaygrounds/${playground.playgroundId}`,
      );
      if (playground.timesId) {
        await db.child('timesId').set(playground.timesId);
      } else {
        await db.child('timesId').remove();
      }
    } else {
      const db = database().ref(
        `users/${userId}/enteredPlaygrounds/${playground.playgroundId}`,
      );
      if (playground.timesId) {
        await db.child('timesId').set(playground.timesId);
      } else {
        await db.child('timesId').remove();
      }
    }
  }

  _convertUser(user) {
    let managingPlaygrounds = [];
    for (const managingPlayground in user.managingPlaygrounds) {
      managingPlaygrounds.push(user.managingPlaygrounds[managingPlayground]);
    }
    let enteredPlaygrounds = [];
    for (const enteredPlayground in user.enteredPlaygrounds) {
      enteredPlaygrounds.push(user.enteredPlaygrounds[enteredPlayground]);
    }
    managingPlaygrounds.sort(this._sortPlaygroundsByCreateTime);
    enteredPlaygrounds.sort(this._sortPlaygroundsByEnteredTime);

    return {
      ...user,
      managingPlaygrounds,
      enteredPlaygrounds,
    };
  }

  _sortPlaygroundsByEnteredTime(p1, p2) {
    if (!p1.enteredTime != !p2.enteredTime) {
      return !p1.enteredTime;
    }
    if (!p1.enteredTime || !p2.enteredTime) {
      return p1.createTime < p2.createTime;
    }
    return p1.enteredTime < p2.enteredTime;
  }
  _sortPlaygroundsByCreateTime(p1, p2) {
    return p1.createTime < p2.createTime;
  }

  _convertPlayground(playground) {
    playground.version = playground.version || 1;
    playground = this.makePlaygroundObj(playground);
    const guests = playground.guests;
    for (const round of playground.rounds) {
      //guest에 있지만 round에 없는 유저들 데이터 생성해서 넣어줌
      round.userSkips = this._fillRoundUserSkip(round, playground);
    }

    let rounds = this._sortRounds(playground.rounds);
    for (const round of rounds) {
      this._sortUserSkips(round);
      let userSkips = [];
      for (const userSkip of round.userSkips) {
        const user = guests.find(guest => {
          return userSkip.userId == guest.userId;
        });
        if (user) {
          userSkip.name = user.name;
          userSkips.push(userSkip);
        } else {
          // userSkip.name = '읭?';
          // userSkips.push(userSkip);
        }
      }
      round.userSkips = userSkips;
    }

    playground.rounds = rounds;
    return playground;
  }

  _sortUserSkips(round) {
    round.userSkips.sort((us1, us2) => {
      return us1.name > us2.name;
    });
  }

  _sortRounds(rounds) {
    return rounds.sort((p1, p2) => {
      if (!p1.startTime) {
        return true;
      }
      if (!p2.startTime) {
        return false;
      }
      const mp1 = moment(p1.startTime).tz('Asia/Seoul');
      const mp2 = moment(p2.startTime).tz('Asia/Seoul');

      return mp1.isAfter(mp2);
    });
  }

  _fillRoundUserSkip(round, playground) {
    let userSkips = [];
    for (const userSkip of round.userSkips) {
      userSkips.push(userSkip);
    }
    for (const guest of playground.guests) {
      const find = userSkips.find(skip => {
        return skip.userId == guest.userId;
      });

      if (!find) {
        userSkips.push({
          userId: guest.userId,
          name: guest.name,
          count: 0,
        });
      }
    }
    return userSkips;
  }

  makePlaygroundObj(playground) {
    playground = {...playground};
    let guests = [];
    for (const guest in playground.guests) {
      guests.push(playground.guests[guest]);
    }
    playground.guests = guests;

    let rounds = [];
    for (const round in playground.rounds) {
      let item = playground.rounds[round];

      let userSkips = [];
      for (const userSkip in item.userSkips) {
        userSkips.push(item.userSkips[userSkip]);
      }
      item.userSkips = userSkips;
      rounds.push(item);
    }
    playground.rounds = rounds;
    return playground;
  }

  async createPlayground({host, playgroundName, detail, picture}) {
    const created = database().ref('playgrounds').push();
    const key = created.key.toString();
    const createTime = moment().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');

    let playground = {
      playgroundId: key,
      playgroundName,
      detail,
      picture,
      createTime,
      host: host,
      guests: [],
      rounds: [],
      timesId: moment().unix(),
      version: 2.0,
    };
    await created.set(playground);
    return playground;
  }

  async updatePlayground(
    playgroundId,
    data: {playgroundName: string, detail: string, picture: string},
  ) {
    if (data) {
      data = {
        playgroundName: data.playgroundName,
        detail: data.detail,
        picture: data.picture,
      };
    }

    const db = database().ref(`playgrounds/${playgroundId}`);
    if (data) {
      await db.update(data);
    } else {
      await db.set(null);
    }
  }

  async removePlayground(playgroundId) {
    return this.updatePlayground(playgroundId, null);
  }

  async setPlaygroundTimesId(playgroundId, timesId) {
    const db = database().ref(`playgrounds/${playgroundId}`);
    await db.child('timesId').set(timesId);
  }

  async getPlayground(playgroundId) {
    const snapshot = await database()
      .ref(`playgrounds/${playgroundId}`)
      .once('value');
    let playground = snapshot.val();
    if (playground) {
      playground = this._convertPlayground(playground);
    }

    return playground;
  }

  async addGuest({playgroundId, user}) {
    const db = database().ref(`playgrounds/${playgroundId}/guests`);
    await db.child(user.userId).set(user);
  }
  async updateGuest(playgroundId, userId, user) {
    if (!playgroundId || !userId) {
      throw new Error('Invalid Parameter');
    }
    if (user && user.userId != userId) {
      throw new Error('Invalid Parameter');
    }
    const db = database().ref(`playgrounds/${playgroundId}/guests`);
    if (user) {
      await db.child(userId).update(user);
    } else {
      await db.child(userId).set(user);
    }
  }
  async deleteGuest(playgroundId, userId) {
    await this.updateGuest(playgroundId, userId, null);
  }
  async addManagingPlayground(userId, playground) {
    delete playground.guests;
    delete playground.host;
    delete playground.rounds;

    const db = database().ref(`users/${userId}/managingPlaygrounds`);
    const now = moment().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');
    await db.child(playground.playgroundId).set({
      ...playground,
    });
  }
  async addEnteredPlaygrounds(userId, playground) {
    delete playground.guests;
    delete playground.host;
    delete playground.rounds;

    const db = database().ref(`users/${userId}/enteredPlaygrounds`);
    const now = moment().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');
    await db.child(playground.playgroundId).set({
      ...playground,
      enteredTime: now,
    });
  }
  async createRound(playgroundId, timesId, during, startTime) {
    const created = database().ref(`playgrounds/${playgroundId}/rounds`).push();
    const key = created.key.toString();
    let round = {
      roundId: key,
      userSkips: [],
      startTime: null,
      during: during,
      timesId: timesId,
      state: 'WAIT',
    };
    await created.set(round);
    return round;
  }
  async updateRound(playgroundId, round) {
    if (!round.roundId) {
      throw new Error('Invalid Parameter');
    }
    const db = database().ref(`playgrounds/${playgroundId}/rounds`);
    await db.child(round.roundId).update(round);
  }
  async removeRound(playgroundId, roundId) {
    if (!roundId) {
      throw new Error('Invalid Parameter');
    }
    const db = database().ref(`playgrounds/${playgroundId}/rounds`);
    await db.child(roundId).set(null);
  }
  async updateUserSkip(userId, playgroundId, roundId, skipCount) {
    const db = database().ref(
      `playgrounds/${playgroundId}/rounds/${roundId}/userSkips/${userId}`,
    );
    await db.set({
      userId: userId,
      count: skipCount,
    });
  }
}
