import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';

const columns = [
    { field: 'userName', headerName: '학생이름', width: 200 },
    { field: 'rate', headerName: '성공률', width: 200 },
    { field: 'skipCount', headerName: '수량', width: 200 },
];

const ChallengeInfoComponent = ({ challenge }) => {
    const { userChallengeData } = challenge;

    const rows = Object.values(userChallengeData).map((data) => ({
        id: data.userId,
        userName: data.userName,
        rate: data.rate + '%',
        skipCount: data.skipCount,
    }));

    const exportToExcel = (fileName, sheetName, rows, columns) => {
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        const fileData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([fileData], { type: 'application/octet-stream' }));
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <div></div>
                <Button variant="contained" color="primary" size="small" style={{margin: '10px'}}
                        onClick={() => exportToExcel('challenge_data.xlsx', 'Sheet1', rows, columns)}>
                    엑셀로 내보내기
                </Button>
            </div>
            <div style={{ height: 800, width: '100%'}}>
                <DataGrid rows={rows} columns={columns} pageSize={30} />
            </div>
        </>
    );
};

export default ChallengeInfoComponent;
