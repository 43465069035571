import './Login.css';
import React from "react";
import Amplify from "aws-amplify";
import {useHistory} from "react-router-dom";
import AppButton from "../view/component/AppButton";
import {makeStyles} from '@material-ui/core/styles'
import mangchiImg from './mangchi.png'
import {Checkbox, Snackbar, TextField} from "@material-ui/core";
import {colors} from "../styles/colors";
import AuthContext from "../../domain/auth/Context/AuthContext";
import ApiRequest from "../../domain/api/ApiRequest";
import {Alert, AlertTitle} from "@material-ui/lab";

const Join = () => {
  const history = useHistory();

  //Alert
  const [isAlert, setAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState(0);

  //이름 입력, 이용약관 체크
  const [toggleCheckBox, setToggleCheckBox] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [isNull, setNull] = React.useState('');

  const {signIn} = React.useContext(AuthContext);
  const classes = useStyles();

  //TODO
  // api call로 변경해서 cognito, firebase, db 동기화해야함
  const updateUsername = async () => {
    if (!username) {
      setNull(true);
      return;
    } else {
      setNull(false);
    }
    if (!toggleCheckBox) {
      _setAlert(true, 1);
      return;
    }

    try {
      await ApiRequest.updateUser({username});

      const user = await Amplify.Auth.currentUserInfo();
      const {name, sub} = user.attributes;
      signIn({userId: sub, username: name});

      history.push("/");
    } catch (e) {
      console.log(e);
    }
  };

  const _setAlert = (bool, type) => {
    if (isAlert) {
      setAlert(bool);
      setAlertType(type);
    } else {
      setAlertType(type);
      setAlert(bool);
    }
  };

  return (
      <div className={classes.login_Page}>
        <Snackbar
            open={isAlert}
            autoHideDuration={2000}
            onClose={() => {
              _setAlert(false, 0);
            }}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          {
            alertType == 1 ?
              <Alert severity='error'>
                <AlertTitle>개인정보 이용약관 동의를 해주세요.</AlertTitle>
                이용약관에 동의하지 않는 경우 서비스를 이용할 수 없습니다.
              </Alert> :
            alertType == 2 ?
              <></> :
              <></>
          }
        </Snackbar>

        <div className={classes.login_Container}>
          <img src={mangchiImg} className={classes.login_Image}/>
          <div className={classes.login_Inner_Container}>
            <div className={classes.login_Title}>이름을 입력해주세요.</div>
            <br/>
            <div className={classes.login_Main_text}>다른 사람들에게 보여질 닉네임입니다.</div>
            <div className={classes.login_Main_text}>이후 설정에서 수정할 수 있습니다.</div><br/>
            <hr style={{width: '100%'}}/>
            <br/>
            <div className={classes.login_Input_Container}>
                <TextField
                    required
                    id="phoneNumber2"
                    variant="outlined"
                    value={username}
                    label="이름을 입력해주세요"
                    size="small"
                    onChange={e => {setUsername(e.target.value)}}
                    onKeyDown={e => {
                      if (e.code === 'Enter') {
                        updateUsername();
                      }
                    }}
                    error={isNull}
                    className={classes.login_Input_Text}
                />
              <AppButton onClick={updateUsername} className={classes.login_Get_Code}>가입하기</AppButton>
            </div>
            <br/>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <Checkbox onChange={e => {
                setToggleCheckBox(e.target.checked);
              }}/>
              <div>(필수) 개인정보 이용약관 동의</div>
              <AppButton
                onClick={() => {
                  const win = window.open('https://jumpitnow.com/jumpitnow_private_policy.html', '_blank');
                  win.focus();
                }}
                className={classes.login_Read_Terms}
              >
                전문보기
              </AppButton>
            </div>
          </div>
        </div>
      </div>
  )
};

const useStyles = makeStyles({
  login_Page: {
    flexDirection: 'row',
  },

  login_Container: {
    backgroundColor: colors.VERY_LIGHT_GRAY,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1vh',
  },
  login_Inner_Container: {
    display: 'flex',
    flexDirection: 'column',
  },

  login_Title: {
    color: colors.CHARCOAL,
    fontSize: 'calc(10px + 2vmin)',
  },
  login_Main_text: {
    color: colors.DEEP_GRAY,
    fontSize: 'calc(8px + 2vmin)',
  },

  login_Image: {
    maxHeight: '100vh',
    maxWidth: '30vw',
    verticalAlign: "middle",
    marginRight: '3vw',
  },

  login_Input_Container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  login_Input_Text: {
    fontSize: 'calc(15px + 1vmin)',
    margin: '1vh',
  },
  login_Get_Code: {
    fontSize: 'calc(10px + 1vmin)',
    backgroundColor: colors.PINK,
    '&:hover': {
      backgroundColor: colors.PINK+'AA',
      cursor: 'pointer',
    },
    marginLeft: '1vw',
    color: colors.WHITE,
  },

  login_Read_Terms: {
    fontSize: 'calc(5px + 1vmin)',
    backgroundColor: colors.WHITE,
    '&:hover': {
      backgroundColor: colors.BLACK+'11',
      cursor: 'pointer',
    },
    marginLeft: '1vw',
    padding: 0,
  }
});

export default Join;

