import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import App from "./app/App";
import Login from "./app/auth/Login";
import Join from "./app/auth/Join";
import Playground from "./app/playground/Playground";
import Auth from "./domain/auth/Auth";
// import config from '../aws-exports';
import Amplify from "aws-amplify";
import config from "./config";
import {ClimbingBoxLoader, SyncLoader} from "react-spinners";
import {colors} from "./app/styles/colors";

Amplify.configure(config.awsmobile);


ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Auth
                loading={() => {
                    return(
                        <div style={{display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                            <SyncLoader color={colors.GRAY} size={20}>loading...</SyncLoader>
                            <br/>
                            화면을 불러오는 중입니다...
                        </div>
                    );
                }}
                authed={user => {
                    if (!user.username) {
                        return (
                            <>
                                <Redirect to="/join" push />
                                <Switch>
                                    <Route path={"/login"} component={Login} exact></Route>
                                    <Route path={"/join"} component={Join} exact/>
                                    <Route path={"/"} component={App} ></Route>
                                </Switch>
                            </>
                        );
                    }

                    return (
                        <>
                            <Switch>
                                <Route path={"/login"} component={Login} exact></Route>
                                <Route path={"/join"} component={Join} exact/>
                                <Route path={"/"} component={App} ></Route>
                            </Switch>
                        </>
                    );
                }}
                unAuthed={() => {
                    return (
                        <>
                            <Switch>
                                <Route path={"/login"} component={Login} exact></Route>
                                <Route path={"/join"} component={Join} exact/>
                                <Route path={"/"} component={App} ></Route>
                            </Switch>
                        </>
                    );
                }}
            >
            </Auth>
        </BrowserRouter>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
