const authReducer = (prevState, action) => {
  const {user, type} = action;
  switch (type) {
    case 'SIGN_IN':
      return {
        ...prevState,
        isLoading: false,
        isSignout: false,
        user: user,
      };
    case 'SIGN_OUT':
      return {
        ...prevState,
        isLoading: false,
        isSignout: true,
        user: null,
      };
  }
};

export default authReducer;
