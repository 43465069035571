let awsmobile;
let apiUrl;
let firebaseUrl;
if (false) {
// if (__dev__) {
  console.log('run debug mode');
  awsmobile = {
    aws_project_region: 'ap-northeast-2',
    aws_cognito_identity_pool_id:
      'ap-northeast-1:ccb2e443-7c0d-4205-8309-57eaba07bdd4',
    aws_cognito_region: 'ap-northeast-1',
    aws_user_pools_id: 'ap-northeast-1_klx0ZVYtR',
    aws_user_pools_web_client_id: '242ecovqfeqv3da7lk6teof0hm',
    aws_user_files_s3_bucket: "jumprope-s3-staging",
    aws_user_files_s3_bucket_region: "ap-northeast-1",
    oauth: {},
  };
  apiUrl =
    'https://za0pnj7y2e.execute-api.ap-northeast-1.amazonaws.com/staging/';
  firebaseUrl = 'https://ropejump-144bf-default-rtdb.firebaseio.com';
} else {
  console.log('run release mode');
  awsmobile = {
    aws_project_region: 'ap-northeast-2',
    aws_cognito_identity_pool_id:
      'ap-northeast-1:51b1285f-b5d3-4e64-bd47-6863cc2382fe',
    aws_cognito_region: 'ap-northeast-1',
    aws_user_pools_id: 'ap-northeast-1_ED3o1vcTJ',
    aws_user_pools_web_client_id: '6c0van4fheo8raum5vtv3fslhg',
    aws_user_files_s3_bucket: "jumprope-s3-prod",
    aws_user_files_s3_bucket_region: "ap-northeast-2",
    oauth: {},
  };
  apiUrl = 'https://soojepq3ne.execute-api.ap-northeast-1.amazonaws.com/prod/';
  firebaseUrl = 'https://ropejump-144bf.asia-southeast1.firebasedatabase.app';
}
export default {
  awsmobile,
  apiUrl,
  firebaseUrl,
};
