import React from "react";
import {SyncLoader} from "react-spinners";
import {colors} from "../../styles/colors";

const AppLoader = props => {
    return (
        <div style={{display: 'flex', minHeight: '100vh', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            <SyncLoader color={colors.GRAY} size={20}/>
            <br/>
            화면을 불러오는 중입니다...
        </div>
    );
}

export default AppLoader;
