import React from "react";
import {ClipLoader} from "react-spinners";
import {colors} from "../../styles/colors";
import {Button} from "@material-ui/core";

const AppButton = props => {
    const [loading, setLoading] = React.useState(false);

    return (
        <Button
            color='inherit'
            size='small'
            variant="contained"
            disabled={loading}
            {...props}
            onClick={async e => {
                if (!loading) {
                    setLoading(true);
                    try {
                        await props.onClick(e)
                    } catch (e) {
                        console.log(e);
                    }
                    setLoading(false);
                }
            }}
        >
            <div style={{color: loading ? 'rgba(0,0,0,0)' : ''}}>
                {props.children}
            </div>
            {
                loading ?
                    <div style={{position: "absolute"}}>
                        <ClipLoader color={colors.GRAY} size={20}/>
                    </div> :
                    <></>
            }
        </Button>
);
}

export default AppButton;
