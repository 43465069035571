import React from "react";
import moment from "moment-timezone";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../styles/colors";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import AppButton from "../view/component/AppButton";

const StateView = ({playground}) => {
  const [guests, setGuests] = React.useState([]);
  const [rounds, setRounds] = React.useState({}); //라운드별 유저스킵
  const [dailyUserSkips, setDailyUserSkips] = React.useState([]); //날짜별 라운드 배열
  const [selectDate, setSelectDate] = React.useState(localStorage.getItem('playgroundId') === playground.playgroundId ? localStorage.getItem('selectDate') : null);
  const refSelectDate = React.useRef(localStorage.getItem('playgroundIdx') === playground.playgroundId ? localStorage.getItem('selectDate') : null);

  const classes = useStyles();

  React.useEffect(() => {
    // 화면이 전환 될 때 이전에 선택했던 날짜 저장
    if (selectDate !== 'null') {
      localStorage.setItem('selectDate', selectDate);
      localStorage.setItem('playgroundId', playground.playgroundId);
    }
  }, [selectDate]);

  React.useEffect(() => {
    const guests = _getGuests(playground);
    const dailyUserSkips = _getDailyUserSkips(playground);
    if (!refSelectDate.current) {
      console.log('hit!');
      _selectDefaultDate(dailyUserSkips);
    }
    setDailyUserSkips(dailyUserSkips);

    const roundUserMap = {};

    const reverseRounds = playground.rounds.slice(0).reverse();
    for (let round of reverseRounds) {
      const {roundId, userSkips} = round;
      if (!roundUserMap[roundId]) {
        roundUserMap[roundId] = {};
      }
      for (let userSkip of userSkips) {
        const {count, name, userId} = userSkip;
        roundUserMap[roundId][userId] = userSkip;
      }
    }
    setRounds(roundUserMap);
    setGuests(guests);
  }, [playground])

  function _filterDailyRounds(filterDate) {
    const dailySkips = dailyUserSkips.find(dailyUserSkip => {
      return dailyUserSkip.date === filterDate
    })
    if (dailySkips && dailySkips.rounds) {
      return dailySkips.rounds;
    } else {
      return [];
    }
  }

  function _getDailyUserSkips(playground) {
    const dailyUserSkips = [];
    const reverseRounds = playground.rounds.slice(0).reverse();
    for (let round of reverseRounds) {
      let m = moment(round.startTime).tz('Asia/Seoul');
      const date = m.format('YYYY-MM-DD');
      const exist = dailyUserSkips.find(dailyUserSkip => {
        return dailyUserSkip.date === date
      })
      if (exist) {
        exist.rounds.push(round);
      } else {
        dailyUserSkips.push({
          date,
          month: moment.monthsShort()[m.month()],
          day: m.date(),
          rounds: [round]
        })
      }
    }
    return dailyUserSkips;
  }

  function _getGuests(playground) {
    const guests: { name: string, userId: string }[] = playground.guests;
    return guests;
  }

  function _selectDefaultDate(dailyUserSkips) {
    if (dailyUserSkips.length > 0) {
      refSelectDate.current = dailyUserSkips[0].date;
      setSelectDate(dailyUserSkips[0].date);
    }
  }

  const getTotalCount = (guest) => {
    let totalCount = 0;
    _filterDailyRounds(refSelectDate.current).map((dailyRound) => {
      totalCount += rounds[dailyRound.roundId][guest.userId].count;
    });

    return totalCount;
  };

  return (
    <div className={classes.state_Page}>
      <div style={{display: 'flex', flexDirection: 'row', alignSelf: 'flex-start'}}>
        {dailyUserSkips.map(daily => {
          return (
              <AppButton
                  // key={daily.date}
                  // to={"#"}
                  onClick={() => {
                    refSelectDate.current = daily.date;
                    setSelectDate(daily.date);
                  }}
                  style={{
                    fontSize: 'calc(6px + 1vmin)',
                    backgroundColor: daily.date === refSelectDate.current ? colors.PINK : colors.GRAY,
                    textDecoration: 'none',
                    color: colors.WHITE,
                    margin: '0.5vw',
                    borderRadius: 10,
                    padding: '0.2vw',
                    width: '2vw',
                    height: '2vw',
                    minWidth: '50px',
                    minHeight: '50px',
                    borderWidth: 0,
                  }}>
                <div>{daily.day}</div>
                <div>{daily.month}</div>
              </AppButton>
          )
        })}
      </div>
      <div style={{alignSelf: 'flex-start', margin: '10px'}}>{`클래스에 참여 중인 학생 ${guests.length}명`}</div>

      <TableContainer className={classes.state_List_Container}>
        <Table id='myTable' className={classes.state_List}>
          <TableHead>
            <TableRow >
              <TableCell key='name' align= 'center' style={{borderWidth: 0}}>이름</TableCell>
              <TableCell key='total' align= 'center' style={{borderWidth: 0}}>종합</TableCell>
              {_filterDailyRounds(refSelectDate.current).slice(0).reverse().map((dailyRound, idx) => {
                return <TableCell key={dailyRound.roundId} align='center' style={{borderWidth: 0}}>{_filterDailyRounds(refSelectDate.current).length - idx}회차</TableCell>
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {guests.map(guest => {
              return (
                <>
                  <TableRow tabIndex={-1} key={guest.userId} className={classes.state_List_Row}>
                    <TableCell key={guest.userId} align='center' className={classes.state_List_Cell}>{guest.name}</TableCell>
                    <TableCell
                        key={`${guest.userId}-total`}
                        align='center'
                        className={classes.state_List_Cell}>
                      {getTotalCount(guest)}
                    </TableCell>
                    {_filterDailyRounds(refSelectDate.current).map((dailyRound) => {
                      return (
                        <TableCell key={`${dailyRound.roundId}-${guest.userId}`} align='center' className={classes.state_List_Cell}>
                          {rounds[dailyRound.roundId][guest.userId].count}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  <div style={{height:'10px'}} />
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

const useStyles = makeStyles({
  state_Page: {
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    minHeight: '80vh',
    padding: '10px',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },

  state_List_Container: {
    width: '98%',
    backgroundColor: colors.LIGHT_GRAY,
    borderRadius: 5,
    borderWidth: 5,
    borderColor: colors.LIGHT_GRAY,
    borderStyle: 'solid',
  },
  state_List: {
    borderWidth: 0,
  },
  state_List_Row: {
    backgroundColor: colors.WHITE,
  },
  state_List_Cell: {
    borderWidth: 0,
    minWidth: '50px',
  },
});

export default StateView;
