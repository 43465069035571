import React from 'react';

//데이터 연동하기
import XLSX from 'xlsx';
import moment from 'moment-timezone';

function DataToExcel(excelData) {
    let Data = [];
    let columnData = ['이름'];
    let column = excelData.rounds;
    let userData = excelData.users;

    column.map(item =>
        columnData.push(String(item.round) + ' 회차\n' + item.date),
    );
    Data.push(columnData);
    userData.map(row => Data.push(rowData(row.name, row.rounds)));

    return Data;
}

function rowData(name, data) {
    let rowData = [];
    rowData.push(name);
    data.map(item => rowData.push(item.count));
    return rowData;
}

export const GetExcelData = skipData => {
    const rounds = skipData[0].rounds.map((round, index) => {
        const {roundId, timesId, startTime, during, count} = round;
        let m = moment(startTime).tz('Asia/Seoul');

        return {
            round: parseInt(index + 1),
            date: moment.monthsShort()[m.month()] + '.' + m.date(),
        };
    });

    const users = skipData.map(userSkip => {
        const rounds = userSkip.rounds.map((round, index) => {
            return {
                round: parseInt(index + 1),
                count: round.count,
            };
        });

        return {
            name: userSkip.name,
            rounds: rounds,
        };
    });

    return {
        rounds: rounds,
        users: users,
    };
};

export const ExportExcel = (excelData, playgroundName) => {
    let convertedData = DataToExcel(excelData);

    /* convert AOA back to worksheet */
    const ws = XLSX.utils.aoa_to_sheet(convertedData);
    // const ws = XLSX.utils.json_to_sheet(viewdata);

    /* build new workbook */
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');

    XLSX.writeFile(wb, playgroundName + '.xlsx');
};
