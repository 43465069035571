import './Login.css';
import React from "react";
import Amplify from "aws-amplify";
import {useHistory} from "react-router-dom";
import AppButton from "../view/component/AppButton";
import {makeStyles} from '@material-ui/core/styles'
import mangchiImg from './mangchi.png'
import {TextField} from "@material-ui/core";
import {colors} from "../styles/colors";
import AuthContext from "../../domain/auth/Context/AuthContext";
import AuthErrorCode from "../../domain/auth/AuthErrorCode";

const Login = () => {
  const history = useHistory();
  const signInUser = React.useRef(null);
  const [sendCode, setSendCode] = React.useState(false);

  const {signIn} = React.useContext(AuthContext);

  const [phoneNumber1, setPhoneNumber1] = React.useState('');
  const [isRightPhoneNumber1, setRightPhoneNumber1] = React.useState(true);
  const [phoneNumber2, setPhoneNumber2] = React.useState('');
  const [isRightPhoneNumber2, setRightPhoneNumber2] = React.useState(true);
  const [confirmCode, setConfirmCode] = React.useState('');
  const [isRightConfirmCode, setRightConfirmCode] = React.useState(true);

  const classes = useStyles();

  React.useEffect(() => {
    Amplify.Auth.currentUserInfo().then(loginUser => {
      if (loginUser && Object.keys(loginUser).length !== 0) {
        document.location.href = "/"
      }
    })
  }, []);

  const doSignIn = async phoneNumber => {
    const user = await Amplify.Auth.signIn(phoneNumber);
    signInUser.current = user;
    setSendCode(true);
  };

  const signUp = async phoneNumber => {
    try {
      if (!phoneNumber) {
        //TODO error
        return;
      }

      const {user} = await Amplify.Auth.signUp({
        username: phoneNumber,
        password: Date.now().toString(),
        attributes: {
          phone_number: phoneNumber,
        },
      });
      return user;
    } catch (e) {
      if (e.code === 'UsernameExistsException') {
        //TODO error
        throw new Error(AuthErrorCode.SignUpErrorCode.EmailExistsException);
      }
      if (e.code == 'InvalidParameterException') {
        console.log('error signing up:', e);
        //TODO error
        throw new Error(AuthErrorCode.SignUpErrorCode.InvalidParameter);
      }
      console.log('error signing up:', e);
      // throw e;
      //TODO error
    }
  };

  const sendConfirmCode = async () => {
    const _isRightPN1 = (/^([0-9]){4}$/).test(phoneNumber1);
    const _isRightPN2 = (/^([0-9]){4}$/).test(phoneNumber2);

    if (!_isRightPN1) {
      setRightPhoneNumber1(false);
    } else {
      setRightPhoneNumber1(true);
    }
    if (!_isRightPN2) {
      setRightPhoneNumber2(false);
    } else {
      setRightPhoneNumber2(true);
    }

    if (_isRightPN1 && _isRightPN2) {
      const phoneNumber = `+8210${phoneNumber1}${phoneNumber2}`
      try {
        await doSignIn(phoneNumber);
      } catch (e) {
        if (e.message && e.message.search('User does not exist.') > 0) {
          await signUp(phoneNumber);
          await doSignIn(phoneNumber);
          return;
        }
      }
    }
  }

  const confirmConfirmCode = async () => {
    if (!(/^([0-9]){4}$/).test(confirmCode)) {
      setRightConfirmCode(false);
      return;
    }
    try {
      const user = await Amplify.Auth.sendCustomChallengeAnswer(
          signInUser.current,
          confirmCode,
      );
      console.log(user);
      if (!user.attributes.name) {
        history.push("/join");
      } else {
        const user = await Amplify.Auth.currentUserInfo();
        const {name, sub} = user.attributes;
        signIn({userId: sub, username: name});
      }
    } catch (e) {
      console.log(e);
      //TODO error
      // 3번 이상 실패한 경우 처리하기
    }
  };

  return (
      <div className={classes.login_Page}>
        <div className={classes.login_Container}>
          <img src={mangchiImg} className={classes.login_Image}/>
          <div className={classes.login_Inner_Container}>
            <div className={classes.login_Title}>JUMP IT NOW PC버전입니다.</div>
            <div className={classes.login_Title}>점프잇나우는 전화번호로 가입 및 로그인됩니다.</div><br/>
            <div className={classes.login_Main_text}>본 사이트는 ZOOM을 PC에서 활용하기 위한 PC버전입니다.</div>
            <div className={classes.login_Main_text}>모바일 환경에서는 화면이 정상적으로 나오지 않을 수 있습니다.</div><br/>
            <hr style={{width: '100%'}}/>
            <br/>
            <div className={classes.login_Input_Container}>
              <form className={classes.login_Input_Container} noValidate autoComplete="off">
                <TextField
                    InputProps={{
                      readOnly: true,
                    }}
                    id="phone-number-default"
                    variant="outlined"
                    value={'010'}
                    size="small"
                    className={classes.login_Input_Text}
                />
                <span>-</span>
                <TextField
                    required
                    id="phoneNumber1"
                    variant="outlined"
                    value={phoneNumber1}
                    label="필수"
                    size="small"
                    onChange={e => {setPhoneNumber1(e.target.value)}}
                    helperText={!isRightPhoneNumber1 ? '올바른 전화번호를 입력해주세요' : ''}
                    error={!isRightPhoneNumber1}
                    className={classes.login_Input_Text}
                />
                <span>-</span>
                <TextField
                    required
                    id="phoneNumber2"
                    variant="outlined"
                    value={phoneNumber2}
                    label="필수"
                    size="small"
                    onChange={e => {setPhoneNumber2(e.target.value)}}
                    onKeyDown={e => {
                      if (e.code === 'Enter') {
                        sendConfirmCode();
                      }
                    }}
                    helperText={!isRightPhoneNumber2 ? '올바른 전화번호를 입력해주세요' : ''}
                    error={!isRightPhoneNumber2}
                    className={classes.login_Input_Text}
                />
              </form>
              <AppButton onClick={sendConfirmCode} className={classes.login_Get_Code}>인증번호 받기</AppButton>
            </div>
            {sendCode ?
                <div className={classes.login_Confirm_Container}>
                  <TextField
                      required
                      id="confirmCode"
                      variant="outlined"
                      size="small"
                      value={confirmCode}
                      label="인증번호(4자리)를 입력해주세요."
                      onChange={(e) => {setConfirmCode(e.target.value)}}
                      onKeyDown={e => {
                        if (e.code === 'Enter') {
                          confirmConfirmCode();
                        }
                      }}
                      helperText={!isRightConfirmCode ? '4자리의 인증번호를 입력해주세요' : ''}
                      error={!isRightConfirmCode}
                      className={classes.login_Input_Confirm_Code}
                  />
                  <AppButton onClick={confirmConfirmCode} className={classes.login_Confirm_Code}>확인</AppButton>
                </div> :
                <></>
            }
          </div>
        </div>
      </div>
  )
}

const useStyles = makeStyles({
  login_Page: {
    flexDirection: 'row',
  },

  login_Container: {
    backgroundColor: colors.VERY_LIGHT_GRAY,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1vh',
  },
  login_Inner_Container: {
    display: 'flex',
    flexDirection: 'column',
  },

  login_Title: {
    color: colors.CHARCOAL,
    fontSize: 'calc(10px + 2vmin)',
  },
  login_Main_text: {
    color: colors.DEEP_GRAY,
    fontSize: 'calc(8px + 2vmin)',
  },

  login_Image: {
    maxHeight: '100vh',
    maxWidth: '30vw',
    verticalAlign: "middle",
    marginRight: '3vw',
  },

  login_Input_Container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  login_Input_Text: {
    fontSize: 'calc(15px + 1vmin)',
    margin: '1vh',
  },
  login_Get_Code: {
    fontSize: 'calc(10px + 1vmin)',
    backgroundColor: colors.PINK,
    '&:hover': {
      backgroundColor: colors.PINK+'AA',
      cursor: 'pointer',
    },
    marginLeft: '1vw',
    color: colors.WHITE,
  },

  login_Confirm_Container: {
    display: 'flex',
    flexDirection: 'row',
    margin: '1vh',
    alignItems: 'center',
  },
  login_Input_Confirm_Code: {
    fontSize: 'calc(15px + 1vmin)',
    minWidth: 270,
  },
  login_Confirm_Code: {
    fontSize: 'calc(10px + 1vmin)',
    backgroundColor: colors.PINK,
    '&:hover': {
      backgroundColor: colors.PINK+'AA',
      cursor: 'pointer',
    },
    color: colors.WHITE,
    marginLeft: '1vw',
  }
});

export default Login;

