import './App.css';
import Amplify from 'aws-amplify';
// import config from '../aws-exports';
import {Route, Switch, useHistory} from "react-router-dom";
import AppIndex from "./AppIndex";
import Playground from "./playground/Playground";
import React from "react";
import playgroundService from "../domain/playground/PlaygroundService";
import AppLoader from "./view/component/AppLoader";
// import config from '../config';
// Amplify.configure(config.awsmobile);

function App() {
  const history = useHistory()
  const [loading, setLoading] = React.useState(true);
  const [loginUser, setLoginUser] = React.useState(null);

  React.useEffect(() => {
    console.log("APP INIT");
    const init = async () => {
      const loginUser = await Amplify.Auth.currentUserInfo()

      if(!loginUser){
        history.push("/login")
        return;
      } else if (Object.keys(loginUser).length === 0) {
        history.push("/login")
        return;
      }

      await connectPlayground();
      console.log('connect')
      setLoginUser(loginUser);
      setLoading(false)
    }
    init();
  }, []);

  const connectPlayground = async () => {
    const userInfo = await Amplify.Auth.currentUserInfo();

    const {name, sub} = userInfo.attributes;
    const user = {userId: sub, username: name}
    await playgroundService.connect(user.userId)
  }

  if (loading) {
    return <AppLoader/>;
  }
  return (
    <div className="App">
      <Switch>
        <Route path={"/"} component={AppIndex} exact></Route>
        <Route path={"/playground/:playgroundId/:zoomData"} component={Playground} exact></Route>
        <Route path={"/playground/:playgroundId"} component={Playground} exact></Route>
      </Switch>
    </div>
  );
}

export default App;
