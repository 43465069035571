import axios from 'axios';
import {Auth} from 'aws-amplify';
import config from "../../config";

const baseUrl = config.apiUrl;

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 12000,
});

instance.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    const token = await getToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: token,
      };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

const getToken = async () => {
  try {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    return token;
  } catch (e) {
    return null;
  }
};

export default instance;
