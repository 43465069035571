import { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ChallengeInfoComponent from "./ChallengeInfoComponent";
import * as XLSX from 'xlsx';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  exportButton: {
    margin: '20px 0',
    padding: '10px 20px',
    cursor: 'pointer',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
  },
}));

const ChallengeComponent = ({ playground }) => {
  const classes = useStyles();
  const challenges = playground.challenges ? Object.values(playground.challenges) : [];
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setActiveTabIndex(newIndex);
  };

  // Function to generate a unique sheet name
  const getUniqueSheetName = (workbook, name) => {
    let uniqueName = name;
    let counter = 1;

    // Check if the name already exists in the workbook
    while (workbook.SheetNames.includes(uniqueName)) {
      uniqueName = `${name}_${counter}`;
      counter++;
    }
    return uniqueName;
  };

  // Export all challenges to Excel
  const exportAllChallengesToExcel = () => {
    const workbook = XLSX.utils.book_new();

    challenges.forEach((challenge) => {
      const rows = Object.values(challenge.userChallengeData).map((data) => ({
        '학생이름': data.userName,
        '성공률': data.rate + '%',
        '수량': data.skipCount,
      }));

      // Convert rows to worksheet
      const worksheet = XLSX.utils.json_to_sheet(rows);

      // Use challenge name or ID as sheet name and make sure it's unique
      const startDate = new Date(challenge.startTime);
      const startDateFormat = `${startDate.getMonth() + 1}.${startDate.getDate()}`;
      const baseSheetName = challenge.name || `Challenge_${startDateFormat}`;
      const sheetName = getUniqueSheetName(workbook, baseSheetName);

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    // Export workbook to Excel file
    XLSX.writeFile(workbook, 'all_challenges_data.xlsx');
  };

  return (
    <div className={classes.root}>
      {/* Export All to Excel Button */}
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ margin: '20px' }}
        onClick={exportAllChallengesToExcel}
      >
        모든 챌린지 엑셀로 내보내기
      </Button>

      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {challenges.map((challenge, index) => {
          const startDate = new Date(challenge.startTime);
          const endDate = new Date(challenge.endTime);
          const startDateFormat = `${startDate.getMonth() + 1}.${startDate.getDate()}`;
          const endDateFormat = `${endDate.getMonth() + 1}.${endDate.getDate()}`;
          return (
            <Tab
              key={challenge.challengeId}
              label={`기간 : ${startDateFormat} ~ ${endDateFormat}`}
            />
          )
        })}
      </Tabs>

      {challenges.map((challenge, index) => (
        <div key={challenge.challengeId} hidden={activeTabIndex !== index}>
          <ChallengeInfoComponent challenge={challenge} />
        </div>
      ))}
    </div>
  );
};

export default ChallengeComponent;
