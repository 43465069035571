import React, {useRef, useState} from 'react';
import {colors} from '../../../styles/colors';
import AppButton from "../AppButton";
import {CountdownCircleTimer} from "react-countdown-circle-timer";
import playgroundService from '../../../../domain/playground/PlaygroundService';
import Round from '../../../../data/model/Round';
import {makeStyles} from "@material-ui/core/styles";
import {Alert, AlertTitle} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import useInterval from "../../../../domain/react/commons/hooks/useInterval";
import {TimePicker} from "./TimePicker";

export const Timer = ({
    isHost,
    isOpen,
    state,
    enableControl,
    onNewRound,
    onStartRound,
    onChangeDuration,
    round,
}) => {
    const classes = useStyles();

    //modal
    const [timePickerModalVisible, setTimePickerModalVisible] =
        React.useState(false);

    //타이머 객체 핸들러
    let initialRemainingTime = Round.getRemainTime(round); //getDuration(round);
    const [timer, setTimer] = React.useState({
        timerKey: Math.floor(Math.random() * 10000),
        initialRemainingTime: initialRemainingTime,
    });
    const timerKey = timer.timerKey;

    //시간표시
    const [remainTime, setRemainTime] = React.useState(initialRemainingTime);
    const refRemainTime = React.useRef();
    const refTimer = React.useRef();

    //상태체크
    const isPlaying = state === 'ING';
    const isFinishing = state === 'FINISH';
    const isWaiting = state === 'WAIT';

    //Alert
    const [isAlert, setAlert] = React.useState(false);
    const [alertType, setAlertType] = React.useState(0);

    React.useEffect(() => {
        //startTime, during이 변경된 경우 타이머를 새로 생성해야 한다.
        createTimer();
    }, [Round.getState(round)]);

    useInterval(() => {
        //남은시간 표시 업데이트
        if (remainTime != refRemainTime.current) {
            setRemainTime(refRemainTime.current);
        }
    }, 300);

    useInterval(() => {
        //CountdownCircleTimer가 실제보다 느리게 시간이 흘러가는 경우가 있어서
        //오차가 1초이상 발생하였을 때 시간을 동기화해줌
        let duration = Round.getRemainTime(round);
        if (refRemainTime.current - duration > 1) {
            createTimer();
        }
    }, 2000);

    React.useEffect(() => {
        //끝난경우 강제로 타이머뷰를 업데이트하여 잔여 써클을 없앤다.
        if (isFinishing) {
            console.log('라운드가 종료되어 뷰를 업데이트함');
            let newTimer = Math.floor(Math.random() * 10000);
            refTimer.current = newTimer;
            setTimer({
                timerKey: newTimer,
                initialRemainingTime: 0,
            });
        }
    }, [isFinishing]);

    const createTimer = () => {
        let duration = Round.getRemainTime(round);
        let newTimer = Math.floor(Math.random() * 10000);
        refTimer.current = newTimer;
        setTimer({
            timerKey: newTimer,
            initialRemainingTime: duration,
        });
    };

    const _setAlert = (bool, type) => {
        if (isAlert) {
            setAlert(bool);
            setAlertType(type);
        } else {
            setAlertType(type);
            setAlert(bool);
        }
    };

    return (
        <div className={classes.container}>
            {timePickerModalVisible ?
                <TimePicker
                    onRequestClose={() => {
                        setTimePickerModalVisible(false);
                    }}
                    onSetTime={async seconds => {
                        await playgroundService.setRoundTime(seconds * 1000);
                    }}/> :
                <div className={classes.innerContainer}>
                    <div className={classes.timerContainer}>
                        <CountdownCircleTimer
                            key={timerKey}
                            renderAriaTime={({remainingTime, elapsedTime}) => {
                                if (refTimer.current == timerKey) {
                                    //아래는 다른 클로저 영역이므로 포인터를 활용해서 state변경을 해야한다.
                                    refRemainTime.current = remainingTime * 1000;
                                }
                            }}
                            size={230}
                            strokeWidth={25}
                            trailColor={'rgba(0,0,0,0)'}
                            onComplete={() => {
                                console.log('complete');
                            }}
                            isPlaying={isPlaying}
                            duration={round.during / 1000 || 1}
                            colors={[['rgba(0,0,0,0)', 1]]}
                            rotation="counterclockwise"
                            initialRemainingTime={Math.round(initialRemainingTime / 1000)}>
                            <TimeTextView
                                isOpen={isOpen}
                                round={round}
                                remainTime={remainTime}
                                isHost={isHost}
                            />
                        </CountdownCircleTimer>
                    </div>

                    <AppButton
                        disabled={!isHost}
                        onClick={() => {
                            if (isFinishing) {
                                _setAlert(true, 1);
                                return;
                            }
                            if (!isWaiting) {
                                _setAlert(true, 2);
                                return;
                            }
                            setTimePickerModalVisible(true);
                            // onChangeDuration();
                        }}
                        className={classes.button}>
                        시간 설정
                    </AppButton>

                    {enableControl ? (
                        <Controls
                            state={state}
                            onNewRound={onNewRound}
                            onStartRound={onStartRound}
                        />
                    ) : (
                        <>111</>
                    )}
                </div>
            }

            <Snackbar
                open={isAlert}
                autoHideDuration={2000}
                onClose={() => {
                    _setAlert(false, 0);
                }}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                {
                    alertType == 1 ?
                        <Alert severity='error'>
                            <AlertTitle>시간을 변경할 수 없습니다.</AlertTitle>
                            다시하기를 눌러 새로운 라운드를 시작해주세요.
                        </Alert> :
                    alertType == 2 ?
                        <></> :
                        <></>
                }
            </Snackbar>
        </div>
    );
};

function TimeTextView({isOpen, round, remainTime, isHost}) {
    const classes = useStyles();

    const {during, remain} = round;

    // console.log("HIT")
    // console.log(remainTime)
    // console.log(remain)
    // console.log(during)
    const state = Round.getState(round);
    const isPlaying = state === 'ING';
    const isPause = state === 'PAUSE';
    const isFinishing = state === 'FINISH';
    const isWaiting = state === 'WAIT';
    const isShot = state === 'SHOT';

    const [shottingTime, setShottingTime] = useState(0);

    const roundRef = useRef();
    roundRef.current = round;
    React.useEffect(() => {
        if (isShot) {
            let id = setInterval(() => {
                const round = roundRef.current;
                if (round) {
                    const shottingTime = Round.getShottingTime(round);
                    setShottingTime(parseInt(shottingTime / 1000));
                }
            }, 100);
            return () => {
                setShottingTime(0);
                clearInterval(id);
            };
        }
    }, [isShot]);

    const getTimeText = time => {
        // console.log(`time is ${time}`);

        let timeText;

        time >= 3600000
            ? (timeText = new Date(time).toISOString().substr(11, 8))
            : time >= 60000
            ? (timeText = new Date(time).toISOString().substr(14, 5))
            : (timeText = new Date(time).toISOString().substr(17, 2));

        return timeText;
    };
    if (isHost) {
        return (
            <div style={{display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-around'}}>
                <div className={classes.leftTimeText}>
                    {isPlaying
                        ? '남은 시간'
                        : !isOpen
                        ? '수업 종료'
                        : isWaiting
                        ? '시간 설정'
                        : isFinishing
                        ? '라운드 종료'
                        : isShot
                        ? shottingTime
                        : isPause
                        ? '일시정지'
                        : ''}
                </div>
                <div className={classes.leftTime}>
                    {isPlaying
                        ? getTimeText(remainTime)
                        : isPause
                        ? getTimeText(remain)
                        : getTimeText(during)}
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}
function Controls({state, onNewRound, onStartRound}) {
    const classes = useStyles();

    const isPlaying = state === 'ING';
    const isFinishing = state === 'FINISH';
    const isWaiting = state === 'WAIT';
    const isShot = state === 'SHOT';
    const isPause = state === 'PAUSE';
    return (
        <>
            {isFinishing ? (
                <AppButton
                    className={classes.button}
                    onClick={async () => {
                        //TODO
                        onNewRound();
                    }}
                >다시하기</AppButton>
            ) : (
                <></>
            )}
            {isWaiting ? (
                <AppButton
                    className={classes.button}
                    onClick={async () => {
                        onStartRound();
                    }}
                >시작하기</AppButton>
            ) : (
                <></>
            )}
            {isPlaying ? (
                <AppButton
                    className={classes.button}
                    onClick={async () => {
                        try {
                            await playgroundService.pauseSkip();
                        } catch (e) {
                            console.log(e);
                        }
                    }}
                >일시정지</AppButton>
            ) : (
                <></>
            )}
            {isShot ? (
                <AppButton
                    disabled={true}
                    className={classes.button}
                >준비</AppButton>
            ) : (
                <></>
            )}
            {isPause ? (
                <>
                    <AppButton
                        onClick={async () => {
                            try {
                                await playgroundService.restartSkip();
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                        className={classes.button}
                    >다시시작</AppButton>
                    <AppButton
                        onClick={async () => {
                            try {
                                await playgroundService.stopRound();
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                        className={classes.button}
                    >종료</AppButton>
                </>
            ) : (
                <></>
            )}
        </>
    );
}


const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '23vw',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    innerContainer: {
        display: 'flex',
        width: '22vw',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    timerContainer: {
        display: 'flex',
        width: '22vw',
        backgroundColor: colors.LIGHT_GRAY,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '15vh',
    },

    leftTimeText: {
        color: colors.PINK,
        fontSize: 'calc(1px + 4.5vmin)',
        fontWeight: 'bold',
    },
    leftTime: {
        color: colors.PINK,
        fontSize: 'calc(1px + 5vmin)',
        fontWeight: 'bold',
    },

    button: {
        fontSize: 'calc(7px + 1vmin)',
        fontWeight: 'bold',
        width: '50%',
        alignSelf: 'center',
        margin: '1vh',
        paddingTop: '1vh',
        paddingBottom: '1vh',
    },
});
