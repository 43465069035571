import UserSkip from './UserSkip';
import moment from 'moment-timezone';

export default class Round {
  roundId: string;
  userSkips: UserSkip[];
  startTime: string; //yyyy-mm-dd HH:ss:mm, null일 경우 대기상태
  during: number;
  state: string;
  remain: number;
  restartTime: string;
  endTime: string;

  static getState(round) {
    if (round.state == 'ING') {
      const now = moment().tz('Asia/Seoul');
      const startTime = moment(round.startTime).tz('Asia/Seoul');
      if (startTime.isAfter(now)) {
        return "SHOT";
      }

      const restartTime = moment(round.restartTime).tz('Asia/Seoul');
      const delta = Math.abs(now.diff(restartTime));
      const remain = round.remain - delta;
      if (remain < 0) {
        return "FINISH";
      }
    }
    return round.state;
  }

  static getRemainTime(round) {
    switch (this.getState(round)) {
      case 'WAIT':
        return round.during;
      case 'SHOT':
        return 0;
      case 'ING':
        const now = moment().tz('Asia/Seoul');
        const restartTime = moment(round.restartTime).tz('Asia/Seoul');
        const delta = Math.abs(now.diff(restartTime));
        const remain = round.remain - delta;
        if (remain < 0) {
          return 0;
        }
        return remain;
      case 'PAUSE':
        return round.remain;
      case 'FINISH':
        return 0;
    }
    return 0;
  }

  static getShottingTime(round) {
    if (this.getState(round) != 'SHOT') {
      return 0;
    }
    const now = moment().tz('Asia/Seoul');
    const restartTime = moment(round.restartTime).tz('Asia/Seoul');
    const delta = Math.abs(now.diff(restartTime));
    return delta;
  }
}
