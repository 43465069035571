class ControlIdRepository {
  async getControlId() {
    let controlId = localStorage.getItem('CONTROL_ID')
    if (!controlId) {
      controlId = parseInt(Math.random() * 10000);
      await this.saveControlId(controlId);
    }
    return controlId;
  }
  async saveControlId(controlId) {
    if (controlId == null) {
      localStorage.removeItem('CONTROL_ID');
    } else {
      localStorage.setItem('CONTROL_ID', controlId);
    }
  }
}

const controlIdRepository = new ControlIdRepository();
export default controlIdRepository;
