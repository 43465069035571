const User = require('./User');
const Round = require('./Round');

export default class Playground {
  playgroundId: String;
  playgroundName: String;
  detail: String;
  host: User;
  guests: User[];
  rounds: Round[];
  timesId: String;
}
