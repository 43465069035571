export const colors = {
    CHARCOAL: "#393939",
    WHITE: "#ffffff",
    GRAY: "#c0c0c0",
    VERY_LIGHT_GRAY: "#f8f8f8",
    DEEP_SKY_BLUE: "#0f74f7",
    LIGHT_GRAY: "#dfdfdf",
    YELLOW: "#ffe43f",
    PINK: "#ff9494",
    DEEP_GRAY: "#707070",
    LIGHT_BLUE: "#89cfd8",
    PEACHY_PINK: "#ff8181",
    LIGHT_PINK: "#ffb3b3",
    CORAL: "#ff3c3c",
    GREEN: "#008000",
    BLACK: "#000000"
}
