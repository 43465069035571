import FirebaseRepository from '../../data/repository/firebase/FirebaseRepository';
import User from '../../data/model/User';
import {userDetailObserverHandler} from './observer/MyPlaygrounds';
import RunningPlaygroundHandler from './observer/RunningPlaygroundHandler';
import moment from 'moment-timezone';
import UserSkip from '../../data/model/UserSkip';
import Round from '../../data/model/Round';
import {dateDiffNow} from './Util';
import controlIdRepository from '../../data/repository/firebase/ControlIdRepository';

const Playground = require('../../data/model/Playground');

class PlaygroundService {
  static instance;
  constructor() {
    if (PlaygroundService.instance) {
      return PlaygroundService.instance;
    } else {
      PlaygroundService.instance = this;
    }
  }
  _firebaseRepository;
  _user;
  _playground;

  _managingPlaygrounds;
  _enteredPlaygrounds;

  _runningPlaygroundObserverHandler; //observer handler
  _myPlaygroundsObserver; //observer
  _runningPlaygroundObserver; //observer

  // _onHostDisconnectRef; //호스트인 경우 disconnect 이벤트 등록

  _checkControlPlayground() {
    if (!this._firebaseRepository) {
      throw new Error('playground service가 connect되지 않았습니다.');
    }

    if (!this.playgroundId) {
      throw new Error(
        'playground service가 어떤 playground에도 입장하지 않았습니다.',
      );
    }

    // 플레이그라운드 호스트 체크
    if (this._playground.host.userId != this._user.userId) {
      throw new Error('NOT_HOST');
    }

    if (this._playground.controller && this._playground.controller !== this.controlId) {
      throw new Error('NOT_CONTROLLABLE');
    }
  }

  _checkControlRound() {
    this._checkControlPlayground();
    const timesId = this._playground.timesId;
    if (!timesId) {
      throw new Error('플레이그라운드가 닫힘 상태입니다.');
    }
  }

  async connect(userId) {
    return new Promise(async (resolve, reject) => {
      if (this._playground) {
        await this.disconnectPlayground();
      }
      userDetailObserverHandler.clear();
      // this._user = null;
      if (this._firebaseRepository) {
        this._firebaseRepository.destroy();
        this._firebaseRepository = null;
      }
      this.controlId = await controlIdRepository.getControlId();
      this._firebaseRepository = new FirebaseRepository(userId);
      userDetailObserverHandler.observe(
        this._firebaseRepository,
        userId,
        userDetail => {
          let {userId, name, managingPlaygrounds, enteredPlaygrounds} =
            userDetail;
          this._user = {userId, name};

          this._managingPlaygrounds = managingPlaygrounds;
          this._enteredPlaygrounds = enteredPlaygrounds;

          if (this._myPlaygroundsObserver) {
            this._myPlaygroundsObserver(
              managingPlaygrounds,
              enteredPlaygrounds,
            );
          }

          if (resolve) {
            resolve();
            resolve = null;
          }
        },
      );
    });
  }
  async disconnect() {
    if (this._playground) {
      await this.disconnectPlayground();
    }
    this.clearMyPlaygroundObserver();
    userDetailObserverHandler.clear();
    // this._user = null;
    if (this._firebaseRepository) {
      this._firebaseRepository.destroy();
      this._firebaseRepository = null;
    }
  }

  setMyPlaygroundsObserver(
    observer: (
      managingPlaygrounds: Playground[],
      enteredPlaygrounds: Playground[],
    ) => void,
  ) {
    this._myPlaygroundsObserver = observer;
    if (this._managingPlaygrounds && this._enteredPlaygrounds) {
      this._myPlaygroundsObserver(
        this._managingPlaygrounds,
        this._enteredPlaygrounds,
      );
    }
  }
  clearMyPlaygroundObserver() {
    this._myPlaygroundsObserver = null;
  }

  async createPlayground(playgroundName, detail, picture) {
    if (!this._firebaseRepository) {
      throw new Error('playground service is not connected');
    }

    if (!this._user) {
      throw new Error('user is not setting');
    }

    //1. create playground
    let playground = await this._firebaseRepository.createPlayground({
      host: this._user,
      playgroundName,
      detail,
      picture,
    });

    const timesId = playground.timesId;

    //2. 기본 라운드 추가
    const round = await this._firebaseRepository.createRound(
      playground.playgroundId,
      timesId,
      60000,
    );

    await this.joinPlayground(playground.playgroundId);

    //3. add playground to managingPlaygrounds
    await this._firebaseRepository.addManagingPlayground(
      this._user.userId,
      playground,
    );
    return {...playground, rounds: [round]};
  }

  /*
  수업을 시작함
  호스트가 방에 입장했을때만 가능
   */
  async openPlayground() {
    this._checkControlPlayground();

    const timesId = this._playground.timesId;
    if (timesId) {
      throw new Error('플레이그라운드가 이미 오픈상태입니다.');
    }

    await this._firebaseRepository.setPlaygroundTimesId(
      this.playgroundId,
      moment().unix(),
    );
    await this._updateUserPlaygroundTimesId(this._playground);

    const runningRound = this.getRunningRound();
    if (Round.getState(runningRound) === 'WAIT') {
      const timesId = this._playground.timesId;
      let roundId = runningRound.roundId;
      await this._updateRoundTimesId(roundId, timesId);
    } else {
      await this.createRound(60000);
    }
  }

  /*
    수업을 종료함
    호스트가 방에 입장했을때만 가능
    라운드가 진행중이면 불가능
     */
  async closePlayground() {
    this._checkControlPlayground();

    const timesId = this._playground.timesId;
    if (!timesId) {
      throw new Error('플레이그라운드가 이미 닫힌 상태입니다.');
    }

    const runningRound = this.getRunningRound();
    if (!runningRound) {
      throw new Error('라운드가 없습니다.');
    }
    if (
      Round.getState(runningRound) != 'FINISH' &&
      Round.getState(runningRound) != 'WAIT'
    ) {
      throw new Error('라운드가 진행중입니다.');
    }
    const rounds = this._playground.rounds;
    const noRoundStarted =
      rounds.length === 1 && Round.getState(runningRound) === 'WAIT';

    //플레이그라운드 내에 어떠한 라운드도 시작한 이력이 없으면 라운드를 종료하지 않는다.
    //첫 라운드가 종료되면 버그가 있음
    if (!noRoundStarted) {
      await this.stopRound();
    } else {
      await this._updateRoundTimesId(runningRound.roundId, null);
    }

    await this._firebaseRepository.setPlaygroundTimesId(
      this.playgroundId,
      null,
    );
    await this._updateUserPlaygroundTimesId(this._playground);
  }

  async updatePlayground(
    playgroundId: string,
    data: {playgroundName: string, detail: string, picture: string},
  ) {
    data = {
      playgroundName: data.playgroundName,
      detail: data.detail,
      picture: data.picture,
    };
    await this._firebaseRepository.updatePlayground(playgroundId, data);
    const playground = await this.getPlayground(playgroundId);
    const {guests} = playground;
    const promises = [];
    guests.map(guest => {
      promises.push(
        this._firebaseRepository.updateUserPlayground(
          guest.userId,
          playground,
          data,
        ),
      );
    });
    return Promise.all(promises);
  }
  async _updateUserPlaygroundTimesId(playgroundId) {
    const guests = this._playground.guests;
    const promises = [];
    for (const guest of guests) {
      promises.push(
        this._firebaseRepository.updateUserPlaygroundTimesId(
          guest.userId,
          playgroundId,
        ),
      );
    }
    await Promise.all(promises);
  }

  async connectPlayground(
    playgroundId,
    observer: (data: {
      isHost: boolean,
      mySkip: UserSkip,
      userSkips: {userId: string, name: string, rounds: number[]}[],
      runningRound: Round,
      playground: {playground: Playground, isOpen: boolean},
    }) => void,
  ) {
    return new Promise(async (resolve, reject) => {
      if (!this._firebaseRepository) {
        throw new Error('playground service is not connected: 1');
      }
      if (!this._user) {
        throw new Error('playground service is not connected: 2');
      }

      await this.disconnectPlayground();
      this._runningPlaygroundObserver = observer;

      //1. regist running playground observer
      this._runningPlaygroundObserverHandler = new RunningPlaygroundHandler(
        this._firebaseRepository,
      );

      let firstAction = true;
      this._runningPlaygroundObserverHandler.observe(
        playgroundId,
        async (playground: Playground) => {
          if (firstAction) {
            await this._processPromise(playground, resolve, reject);
            this.playgroundId = playgroundId;
            firstAction = false;
          }
          if (!playground) {
            if (this._runningPlaygroundObserver) {
              this._runningPlaygroundObserver(null);
            }
            return;
          }
          this._playground = playground;

          if (this._runningPlaygroundObserver) {
            const guests = playground.guests;
            const rounds = playground.rounds;

            let runningRound = null;
            if (rounds.length != 0) {
              runningRound = rounds[rounds.length - 1];
              runningRound.userSkips.sort((us1, us2) => {
                return us1.count < us2.count;
              });
            }
            const allRounds = rounds;

            let mySkip = {
              userId: this._user.userId,
              name: this._user.name,
              count: 0,
            };
            if (runningRound) {
              mySkip =
                runningRound.userSkips.find(userSkip => {
                  return userSkip.userId == mySkip.userId;
                }) || mySkip;
            }

            const host = playground.host;
            const userSkips = guests.map(guest => {
              return {userId: guest.userId, name: guest.name, rounds: []};
            });

            for (const userSkip of userSkips) {
              for (const round of allRounds) {
                const roundUserSkips = round.userSkips;
                const roundUserSkip = roundUserSkips.find(roundUserSkip => {
                  return roundUserSkip.userId == userSkip.userId;
                });
                if (roundUserSkip) {
                  userSkip.rounds.push({
                    roundId: round.roundId,
                    timesId: round.timesId,
                    startTime: round.startTime,
                    during: round.during,
                    count: roundUserSkip.count,
                  });
                } else {
                  userSkip.rounds.push({
                    roundId: round.roundId,
                    timesId: round.timesId,
                    startTime: round.startTime,
                    during: round.during,
                    count: 0,
                  });
                }
              }
            }

            const zoom = playground.zoom;
            const runningMode = playground.runningMode;

            this._runningPlaygroundObserver({
              isHost: host.userId == this._user.userId,
              mySkip: mySkip,
              userSkips: userSkips,
              runningRound: runningRound,
              zoom: zoom,
              runningMode,
              controllable: this.canControl(),
              playground: {...playground, isOpen: playground.timesId != null},
            });
          }
        },
      );
    });
  }

  async registHostConnectTrigger(playgroundId) {
    await this._firebaseRepository.cancelHostDisconnectTrigger();
    await this._firebaseRepository.setHostDisconnectTrigger(
      playgroundId,
    );
  }

  async cancelHostConnectTrigger() {
    await this._firebaseRepository.cancelHostDisconnectTrigger();
  }

  async disconnectPlayground() {
    //1. clear observer
    if (this._runningPlaygroundObserverHandler) {
      this._runningPlaygroundObserverHandler.clearObserve();
      this._runningPlaygroundObserverHandler = null;
    }
    if (this.isHost() && this.controlByMe()) {
      await this.stopRunningMode();
    }
    this._playground = null;
    this.playgroundId = null;
  }

  controlByMe() {
    if (!this._playground) {
      return false;
    }
    return this._playground.controller === this.controlId;
  }

  canControl() {
    if (!this._playground) {
      return false;
    }
    if (this._playground.controller && this._playground.controller !== this.controlId) {
      return false;
    }
    return true;
  }

  isHost() {
    if (!this._playground) {
      return false;
    }
    return this._playground.host.userId == this._user.userId;
  }

  async startRunningMode(mode) {
    if (!mode) {
      throw new Error('invalid param');
    }
    this._checkControlPlayground();
    if (!this.canControl()) {
      throw new Error('CAN NOT CONTROLL');
    }
    if (this._playground.runningMode === mode && this.controlByMe()) {
      return;
    }
    await this.registHostConnectTrigger(this.playgroundId);
    await this._firebaseRepository.setControlId(this.playgroundId, this.controlId);
    await this._firebaseRepository.setHostConnect(this.playgroundId, true);
    await this._firebaseRepository.setRunningMode(this.playgroundId, mode);
  }

  async stopRunningMode() {
    this._checkControlPlayground();
    await this.cancelHostConnectTrigger();
    await this._firebaseRepository.setHostConnect(this.playgroundId, false);
    await this._firebaseRepository.removeControlId(this.playgroundId);
    await this._firebaseRepository.removeZoomData(this.playgroundId);
    await this._firebaseRepository.removeRunningMode(this.playgroundId);
  }

  async linkZoom({meetId, password}) {
    this._checkControlPlayground();

    //1. add zoom data to playground
    await this._firebaseRepository.addZoomData(this.playgroundId, {
      meetId,
      password,
    });
    //2. set disconnect trigger => unlink
  }

  async unlinkZoom() {
    this._checkControlPlayground();
    await this._firebaseRepository.removeZoomData(this.playgroundId);
  }

  async _processPromise(playground, resolve, reject) {
    if (!playground) {
      reject(new Error('playground not found'));
    }

    if (playground.host.userId != this._user.userId) {
      const findMe = playground.guests.find(guest => {
        return guest.userId == this._user.userId;
      });
      if (!findMe) {
        await this.joinPlayground(playground.playgroundId);
      }
    }

    resolve(playground);
  }

  async getPlayground(playgroundId) {
    const playground = await this._firebaseRepository.getPlayground(
      playgroundId,
    );
    return playground;
  }
  async joinPlayground(playgroundId) {
    const playground = await this._firebaseRepository.getPlayground(
      playgroundId,
    );
    if (!playground) {
      throw new Error('playground not found');
    }

    const findMe = this.isJoin(playground);
    if (findMe) {
      //이미 join되어있음
      return;
    }

    await this._firebaseRepository.addGuest({
      playgroundId,
      user: this._user,
    });

    //3. 내가 호스트가 아닐때 => add playground to enteredPlaygrounds
    if (playground.host.userId != this._user.userId) {
      await this._firebaseRepository.addEnteredPlaygrounds(
        this._user.userId,
        playground,
      );
    }
  }

  async leavePlayground(data: {playgroundId: string, userId: string}) {
    let {playgroundId, userId} = {};
    let playground;
    if (data) {
      playgroundId = data.playgroundId;
      userId = data.userId;
    }

    if (!playgroundId) {
      playgroundId = this.playgroundId;
      if (!playgroundId) {
        throw new Error('Invalid Parameter');
      }
    }

    if (!userId) {
      userId = this._user.userId;
    }
    if (!userId) {
      throw new Error('Invalid Parameter');
    }

    const hostId = this._playground.host.userId;
    if (hostId == userId) {
      throw new Error('호스트는 방을 나갈 수 없습니다.');
    }

    if (playgroundId == this.playgroundId) {
      playground = this._playground;
    } else {
      playground = this.getPlayground(playgroundId);
    }

    const promise = [];
    promise.push(this._firebaseRepository.deleteGuest(playgroundId, userId));
    promise.push(
      this._firebaseRepository.removeUserPlayground(userId, playground),
    );
    return Promise.all(promise);
  }

  async deletePlayground() {
    this._checkControlPlayground();

    const playgroundId = this.playgroundId;
    const hostUserId = this._playground.host.userId;

    let playground;
    if (playgroundId == this.playgroundId) {
      playground = this._playground;
    } else {
      playground = this.getPlayground(playgroundId);
    }

    // 1. 모든 게스트 추방
    await this._kickOutAllGuests();

    // 2. 호스트 매니지룸 리스트에서 삭제
    await this._firebaseRepository.removeUserPlayground(hostUserId, playground);
    // 3. 방 자체 삭제
    await this._firebaseRepository.removePlayground(playgroundId);
  }

  async _kickOutAllGuests() {
    this._checkControlPlayground();

    // 1. 순수 게스트 추출
    // 2. 게스트 킥아웃
    const guests = this._playground.guests;
    const hostUserId = this._playground.host.userId;
    const playgroundId = this.playgroundId;

    const promise = [];
    for (const guest of guests) {
      if (guest.userId !== hostUserId) {
        promise.push(
          this.leavePlayground({playgroundId, userId: guest.userId}),
        );
      }
    }
    await Promise.all(promise);
  }

  isJoin(playground) {
    const findMe = playground.guests.find(guest => {
      return guest.userId == this._user.userId;
    });
    return findMe != null;
  }

  async createRound(during) {
    this._checkControlRound();
    const runningRound = this.getRunningRound();
    if (Round.getState(runningRound) != 'FINISH') {
      throw new Error('라운드가 FINISH상태가 아닙니다.');
    }

    //1. finish running round
    await this.stopRound();

    //2. add round
    const timesId = this._playground.timesId;
    await this._firebaseRepository.createRound(
      this.playgroundId,
      timesId,
      during,
    );
  }

  async stopRound() {
    this._checkControlRound();
    const round = this.getRunningRound();
    if (round.state == 'FINISH') {
      return;
    }
    let roundId = round.roundId;
    if (!round.startTime) {
      await this._removeRound(round);
    } else {
      const remain = this._getRoundRemainTime(round);
      await this._firebaseRepository.updateRound(this.playgroundId, {
        roundId: roundId,
        state: 'FINISH',
        remain: remain,
      });
    }
  }

  async _removeRound(round) {
    let roundId = round.roundId;
    const rounds = this._playground.rounds;
    const noRoundStarted =
      rounds.length === 1 && Round.getState(round) === 'WAIT';
    if (!noRoundStarted) {
      //첫 라운드는 삭제하지 않는다.
      await this._firebaseRepository.removeRound(this.playgroundId, roundId);
    } else {
      await this._updateRoundTimesId(roundId, null);
    }
  }

  getRunningRound() {
    const rounds = this._playground.rounds;
    if (rounds.length == 0) {
      return null;
    }
    const runningRound = rounds[rounds.length - 1];
    return runningRound;
  }

  async setRoundTime(during) {
    if (!this._firebaseRepository) {
      throw new Error('playground service is not connected');
    }

    if (!this.playgroundId) {
      throw new Error(
        'playground service가 어떤 playground에도 입장하지 않았습니다.',
      );
    }

    // 플레이그라운드 호스트 체크
    if (this._playground.host.userId != this._user.userId) {
      throw new Error('NOT_HOST');
    }

    // playground가 대기상태가 아님
    const rounds = this._playground.rounds;
    if (rounds.length == 0) {
      throw new Error('라운드가 없습니다.');
    }
    const runningRound = rounds[rounds.length - 1];
    if (Round.getState(runningRound) != 'WAIT') {
      throw new Error('라운드가 WAITING상태가 아닙니다.');
    }

    const roundId = runningRound.roundId;

    //1. update round
    await this._firebaseRepository.updateRound(this.playgroundId, {
      roundId,
      during,
    });
  }

  async _updateRoundTimesId(roundId, timesId) {
    await this._firebaseRepository.updateRound(this.playgroundId, {
      roundId: roundId,
      timesId: timesId,
    });
  }

  async startSkip() {
    this._checkControlRound();

    const runningRound = this.getRunningRound();
    if (Round.getState(runningRound) !== 'WAIT') {
      throw new Error('라운드가 WAITING상태가 아닙니다.');
    }

    console.log('start skip');
    console.log(runningRound);

    //시작한 적 없는 라운드가 남아있다면 해당 라운드를 현재 플레이그라운드 timesId와 매핑한다.
    if (runningRound.timesId !== this._playground.timesId) {
      await this._firebaseRepository.updateRound(this.playgroundId, {
        roundId: runningRound.roundId,
        timeId: this._playground.timesId,
      });
    }

    //타이머 구현위해 4초 뒤 시작
    const startTime = moment()
      .add(4, 'seconds')
      .tz('Asia/Seoul')
      .format('YYYY-MM-DD HH:mm:ss');

    //1. update round
    await this._firebaseRepository.updateRound(this.playgroundId, {
      roundId: runningRound.roundId,
      startTime,
      restartTime: startTime,
      state: 'ING',
      remain: runningRound.during,
    });
  }

  async pauseSkip() {
    this._checkControlRound();
    const runningRound = this.getRunningRound();
    if (Round.getState(runningRound) != 'ING') {
      throw new Error('라운드가 ING상태가 아닙니다.');
    }

    let remain = this._getRoundRemainTime(runningRound);
    await this._firebaseRepository.updateRound(this.playgroundId, {
      roundId: runningRound.roundId,
      state: 'PAUSE',
      remain: remain,
    });
  }

  async restartSkip() {
    this._checkControlRound();
    const runningRound = this.getRunningRound();
    if (Round.getState(runningRound) != 'PAUSE') {
      throw new Error('라운드가 PAUSE상태가 아닙니다.');
    }

    const now = moment().tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss');
    await this._firebaseRepository.updateRound(this.playgroundId, {
      roundId: runningRound.roundId,
      state: 'ING',
      restartTime: now,
    });
  }

  _getRoundRemainTime(runningRound: {restartTime: string, remain: number}) {
    const elapsedTime = dateDiffNow(runningRound.restartTime);
    let remain = runningRound.remain - elapsedTime;
    if (remain < 0) {
      remain = 0;
    }
    return remain;
  }

  async updateJumpRopeCount(count) {
    if (!this._firebaseRepository) {
      throw new Error('playground service is not connected');
    }

    if (!this._user) {
      throw new Error('playground service is not connected');
    }

    if (!this.playgroundId) {
      throw new Error(
        'playground service가 어떤 playground에도 입장하지 않았습니다.',
      );
    }

    // playground가 시작상태가 아님
    const rounds = this._playground.rounds;
    if (rounds.length == 0) {
      throw new Error('시작할 라운드가 없습니다.');
    }
    const runningRound = rounds[rounds.length - 1];
    if (Round.getState(runningRound) != 'ING') {
      throw new Error('라운드가 시작상태가 아닙니다.');
    }

    //1. update user skip
    await this._firebaseRepository.updateUserSkip(
      this._user.userId,
      this.playgroundId,
      runningRound.roundId,
      count,
    );
  }
}

const playgroundService = new PlaygroundService();
export default playgroundService;
