import React from 'react';
import Amplify from "aws-amplify";
import {Link} from "react-router-dom";
import playgroundService from "../domain/playground/PlaygroundService";
import './AppIndex.css';
import {makeStyles} from "@material-ui/core/styles";
import AppButton from "./view/component/AppButton";
import {colors} from "./styles/colors";
import {
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import defaultClassImage from './iconDefaultClassImage.png';
import {StorageImage} from "./view/component/StorageImage";
import {CreateModifyPlaygroundModal} from "./playground/CreateModifyModal";
import {CheckIsDeleteModal} from "./playground/CheckIsDeleteModal";
import {Alert, AlertTitle} from "@material-ui/lab";

const AppIndex = () => {
  const classes = useStyles();

  const [isAlert, setAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState(0);

  const [playgrounds, setPlaygrounds] = React.useState([]);
  React.useEffect(() => {
    playgroundService.setMyPlaygroundsObserver((managingPlaygrounds, enteredPlaygrounds) => {
      const playgrounds = [];
      for (const managingPlayground of managingPlaygrounds) {
        const playground = {
          createTime: managingPlayground.createTime,
          detail: managingPlayground.detail,
          host: managingPlayground.host,
          picture: managingPlayground.picture,
          playgroundId: managingPlayground.playgroundId,
          playgroundName: managingPlayground.playgroundName,
          timesId: managingPlayground.timesId,
          version: managingPlayground.version,
        }
        playgrounds.push(playground);
      }
      setPlaygrounds(playgrounds);
    })
  }, []);

  const [isOpenCreateModify, setOpenCreateModify] = React.useState(false);
  const createPlayground = async () => {
    setOpenCreateModify(true);
    console.log('create');
  };

  const logout = async () => {
    try {
      await Amplify.Auth.signOut()
      document.location.href = "/login";
    } catch (e) {
      console.log(e);
    }
  }

  const [isOpenCheckIsDelete, setOpenCheckIsDelete] = React.useState(false);
  const [playgroundId, setPlaygroundId] = React.useState(false);
  const deletePlayground = async (playgroundId) => {
    setPlaygroundId(playgroundId);
    setOpenCheckIsDelete(true);
  };

  const _setAlert = (bool, type) => {
    if (isAlert) {
      setAlert(bool);
      setAlertType(type);
    } else {
      setAlertType(type);
      setAlert(bool);
    }
  };

  return (
      <div className={classes.playgroundList_Page}>
        <div className={classes.playgroundList_Title_Container}>
          <span className={classes.playgroundList_Title}>관리 중인 방 목록</span>
          <div>
            <AppButton
                onClick={createPlayground}
                className={classes.playgroundList_TopButton}>방 만들기</AppButton>
            <AppButton
                onClick={logout}
                className={classes.playgroundList_TopButton}>로그아웃</AppButton>
          </div>
        </div>
        <hr style={{width: '100%'}}/>
        <br/>

        <TableContainer style={{minHeight: '100vh'}}>
          <Table className={classes.playgroundList_List}>
            <TableHead>
              <TableRow>
                {titleColumns.map(column => {
                  return (
                      <TableCell key={column.id} align={column.align} style={column.style}>
                        {column.label}
                      </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {playgrounds.map(playground => {
                return (
                    <TableRow tabIndex={-1} key={playground.playgroundId}>
                      {titleColumns.map(column => {
                        return (
                            <TableCell key={column.id} align={column.align} style={column.style}>
                              {
                                column.id === 'image' ?
                                    (
                                        playground.picture == null ?
                                            <img src={defaultClassImage} className={classes.playgroundList_Image}/> :
                                            playground.picture === 'null' ?
                                                <img
                                                    src={defaultClassImage}
                                                    className={classes.playgroundList_Image}/> :
                                                <StorageImage
                                                    imageKey={playground.picture}
                                                    className={classes.playgroundList_Image}
                                                    type={'PLAYGROUND'}/>
                                    ) :
                                    column.id === 'title' ?
                                        <div style={{
                                          maxHeight: '70px',
                                          overflow: 'hidden'
                                        }}>{playground.playgroundName}</div> :
                                        column.id === 'detail' ?
                                            <div style={{
                                              maxHeight: '70px',
                                              overflow: 'hidden'
                                            }}>{playground.detail}</div> :
                                            column.id === 'join' ?
                                                <div className={classes.playgroundList_Inner_Button_Container}>
                                                  <AppButton className={classes.playgroundList_Inner_Button} onClick={() => document.getElementById(`join${playground.playgroundId}`).click()}>
                                                      입장하기
                                                  </AppButton>
                                                  <Link
                                                      id={`join${playground.playgroundId}`}
                                                      key={playground.playgroundId}
                                                      to={`/playground/${playground.playgroundId}`}
                                                      style={{
                                                        textDecoration: 'none',
                                                      }}
                                                  >
                                                  </Link>
                                                </div> :
                                                column.id === 'delete' ?
                                                    <AppButton
                                                        onClick={() => deletePlayground(playground.playgroundId)}
                                                        className={classes.playgroundList_Inner_Button}>삭제하기</AppButton>
                                                    : <></>
                              }
                            </TableCell>
                        )
                      })}
                    </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <CreateModifyPlaygroundModal
            isOpen={isOpenCreateModify}
            onClose={() => {
              setOpenCreateModify(false);
            }}
            type="CREATE"
        />
        <CheckIsDeleteModal
            isOpen={isOpenCheckIsDelete}
            onClose={async playgroundId => {
              if (playgroundId) {
                try {
                  await playgroundService.connectPlayground(playgroundId);
                  setOpenCheckIsDelete(false);
                  await playgroundService.deletePlayground(playgroundId);
                  _setAlert(true, 2);
                } catch (e) {
                  setOpenCheckIsDelete(false);
                  throw new Error(e);
                }
              } else {
                setOpenCheckIsDelete(false);
              }
            }}
            onAlert={(bool, type) => {
              _setAlert(bool, type);
            }}
            playgroundId={playgroundId}
        />

        <Snackbar
            open={isAlert}
            autoHideDuration={2000}
            onClose={() => {
              _setAlert(false, 0)
            }}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        >
          {
            alertType === 1 ?
                <Alert severity='error'>
                  <AlertTitle>방 삭제 실패.</AlertTitle>
                  방을 삭제할 수 없습니다. 인터넷 연결을 확인하거나 잠시 후 다시 시도해주세요.
                </Alert> :
                alertType === 2 ?
                    <Alert severity='success'>
                      <AlertTitle>삭제 성공.</AlertTitle>
                      방이 삭제되었습니다.
                    </Alert> :
                    <></>
          }
        </Snackbar>
      </div>
  )
}

const titleColumns = [
  {id: 'image', label: '방 이미지', align: 'center', style: {maxWidth: '5vw', borderWidth: 0, borderBottomWidth: 1, borderColor: colors.LIGHT_GRAY}},
  {id: 'title', label: '방 제목', align: 'left', style: {maxWidth: '10vw', borderWidth: 0, borderBottomWidth: 1, borderColor: colors.LIGHT_GRAY,}},
  {id: 'detail', label: '방 설명', align: 'left', style: {maxWidth: '20vw', borderWidth: 0, borderBottomWidth: 1, borderColor: colors.LIGHT_GRAY,}},
  {id: 'join', label: '입장', align: 'center', style: {maxWidth: '5vw', borderWidth: 0, borderBottomWidth: 1, borderColor: colors.LIGHT_GRAY}},
  {id: 'delete', label: '삭제', align: 'center', style: {maxWidth: '5vw', borderWidth: 0, borderBottomWidth: 1, borderColor: colors.LIGHT_GRAY}},
]

const useStyles = makeStyles({
  playgroundList_Page: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.VERY_LIGHT_GRAY,
    minHeight: '100vh',
    paddingLeft: '10vw',
    paddingRight: '10vw',
  },

  playgroundList_Title_Container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: '5vh',
    justifyContent: "space-between"
  },
  playgroundList_Title: {
    fontSize: 'calc(10px + 1vmin)',
  },
  playgroundList_TopButton: {
    fontSize: 'calc(7px + 1vmin)',
    backgroundColor: colors.PINK,
    '&:hover': {
      backgroundColor: colors.PINK+'AA',
      cursor: 'pointer',
    },
    borderWidth: 0,
    marginLeft: '1vw',
    color: colors.WHITE,
    minWidth: '5vw',
  },

  playgroundList_List: {
    width: '100%',
    backgroundColor: colors.WHITE,
    borderColor: colors.LIGHT_GRAY,
    borderRadius: 10,
    borderCollapse: 'separate',
    borderWidth: 1,
    padding: '20px',
  },
  playgroundList_Image: {
    width: '6vw',
    height: '6vw',
    maxHeight: '70px',
    maxWidth: '70px',
    borderRadius: 50,
  },
  playgroundList_Inner_Button_Container: {
    align_items: 'center',
    justifyContent: 'center',
  },
  playgroundList_Inner_Button: {
    backgroundColor: colors.PINK,
    '&:hover': {
      backgroundColor: colors.PINK+'AA',
      cursor: 'pointer',
    },
    fontSize: 'calc(5px + 1vmin)',
    color: colors.WHITE,
    textDecoration: 'none',
  }
});

export default AppIndex;
