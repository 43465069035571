import React from 'react';
import authReducer from './Reducer/AuthReducer';
import {AuthProvider} from './Context/AuthContext';
import Amplify from 'aws-amplify';
import AppLoader from "../../app/view/component/AppLoader";
import {useHistory} from "react-router-dom";

/*
앱 구동시 로그인체크를 합니다.
로그인이 되어있다면 Main 화면을 보여줍니다.
그렇지 않다면 SignIn화면을 보여줍니다.

앱 내 모든 곳에서 로그인, 로그아웃 명령을 수행할 수 있도록
ContextProvider (AuthProvider) 역할을 합니다.
ex:
  const {signOut} = React.useContext(AuthContext);
  signOut();
 */
const Auth = ({authed, unAuthed, loading}) => {
  const history = useHistory();

  const [authState, authDispatch] = React.useReducer(authReducer, {
    isLoading: true,
    isSignout: true,
    user: null,
  });

  const getCurrentUser = async () => {
    try {
      const user = await Amplify.Auth.currentUserInfo();
      const {name, sub} = user.attributes;
      return {
        userId: sub,
        username: name,
      };
    } catch (e) {
      if (e == 'No current user') {
        console.log('로그인되어있지 않음');
      }
      return null;
    }
  };

  React.useEffect(() => {
    const checkSession = async () => {
      const user = await getCurrentUser();
      if (user) {
        console.log('dispatch SIGN_IN');
        authDispatch({
          type: 'SIGN_IN',
          user,
        });
      } else {
        authDispatch({type: 'SIGN_OUT'});
      }
    };
    checkSession();
  }, []);

  React.useEffect(() => {
    if (!isLoading) {
      if (authState.isSignout) {
      } else {
      }
    }
  }, [authState.isSignout]);

  const {isLoading, isSignout, user} = authState;

  const authCalls = {
    signIn: async ({userId, username}) => {
      try {
        authDispatch({
          type: 'SIGN_IN',
          user: {
            userId: userId,
            username: username || '',
          },
        });
        history.push('/');
      } catch (e) {
        throw e;
      }
    },
    signOut: async () => {
      await Amplify.Auth.signOut();
      authDispatch({type: 'SIGN_OUT'});
    },
    getUser: async () => {
      const user = await getCurrentUser();
      return user;
    },
  };
  const authContext = React.useMemo(
    () => ({
      signIn: authCalls.signIn,
      signOut: authCalls.signOut,
      getUser: authCalls.getUser,
    }),
    [],
  );

  if (isLoading) {
    if (loading) {
      return <AppLoader/>;
    } else {
      return <></>;
    }
  }

  if (isSignout) {
    return <AuthProvider value={authContext}>{unAuthed()}</AuthProvider>;
  }
  return <AuthProvider value={authContext}>{authed(user)}</AuthProvider>;
};

export default Auth;
