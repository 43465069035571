import axios from './axios';

interface UserData {
    userId: string;
    username: string;
    picture: string;
    tall: number;
    weight: number;
    age: number;
    gender: 'M' | 'F';
    isPublic: boolean;
    point: number;
}

interface SkipData {
    skipCount: number;
    date: string;
    userId: string;
    device: string;
}

class ApiRequest {
    async updateUser({username, picture, tall, weight, age, gender, isPublic}) {
        const response = await axios.put('profile', {
            user: {
                username,
                picture,
                tall,
                weight,
                age,
                gender,
                isPublic,
            },
        });
        return response.data;
    }

    async getUser(userId): Promise<UserData> {
        const response = await axios.get('profile', {
            params: {
                userId,
            },
        });
        return response.data.user;
    }

    async saveSkips(jumpRopeName, date, count) {
        const response = axios.put('skips', {
            device: jumpRopeName,
            date,
            count,
        });
        return response.data;
    }

    //사용자의 모든 skip데이터를 불러온다.
    //userId가 null인 경우 본인 데이터를 쿼리함.
    async getSkips(userId): Promise<SkipData[]> {
        const response = await axios.get('skips', {
            params: {
                userId,
            },
        });
        return response.data.skips;
    }

    async getJwtToken(): Promise<String> {
        const response = await axios.get('zoomJwtToken');
        return response.data.jwtToken;
    }
}

const instance = new ApiRequest();
export default instance;
