import FirebaseRepository from '../../../data/repository/firebase/FirebaseRepository';
import UserDetail from '../../../data/model/UserDetail';

class UserDetailObserverHandler {
  firebaseRepository;
  observer;

  observe(
    firebaseRepository,
    userId: String,
    observer: (userDetail: UserDetail) => void,
  ) {
    this.firebaseRepository = firebaseRepository;
    this.observer = observer;
    this.firebaseRepository.registUserDetailObserver(userDetail => {
      if (!userDetail) {
        //TODO error
        return;
      }

      this.observer(userDetail);
    });
  }

  clear() {
    if (this.firebaseRepository) {
      this.firebaseRepository.clearUserDetailObserver();
      this.firebaseRepository = null;
    }
  }
}

export const userDetailObserverHandler = new UserDetailObserverHandler();
